import styled from "@emotion/styled";

interface IDirectionSearchWrap {
     showDirectionPointMessage: boolean;
}

export const DirectionSearchWrap = styled.div<IDirectionSearchWrap>`
     height: ${({ showDirectionPointMessage }) => (showDirectionPointMessage ? "70px" : "50vh")};
     width: 100%;
     box-sizing: border-box;

     .search-wrap {
          padding: 0px 16px;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 12px;
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          .search {
               width: 26px;
               height: 26px;
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 50%;
               background-color: #f1f4f9;
          }
          .input-wrap {
               width: 100%;
               box-sizing: border-box;
               height: 49px;

               background-color: #f1f4f9;
               border-radius: 10px;
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: flex-start;
               padding: 16px;
          }
          .title {
               width: 64px;
          }
     }
     .direction-btns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.4px solid #bdbdbd;

          div {
               gap: 6.5px;
               padding: 12px 0px;
               box-sizing: border-box;
               width: 100%;
               display: flex;
               justify-content: center;
               align-items: center;
               color: #206def;
          }
     }
`;

export const DirectionResultWrap = styled.div`
     display: flex;
     justify-content: space-between;
     align-items: center;
     gap: 10px;
     width: 100%;

     color: #1e2530;

     text-overflow: ellipsis;

     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     box-sizing: border-box;
     padding: 0px 16px;

     .results {
          box-sizing: border-box;
          width: 100%;
          display: flex;
          flex-direction: column;
          background-color: #f1f4f9;
          border-radius: 10px;
     }

     .start {
          display: flex;
          padding: 16px 0px;
     }
     .end {
          border-top: 0.4px solid #bdbdbd;

          display: flex;
          padding: 16px 0px;
     }
     .change-btn {
          cursor: pointer;
          width: 26px;
          height: 26px;
          background-color: #f1f4f9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
     }

     .title {
          padding-left: 16px;
          font-weight: 600;
          width: 64px;
     }

     .contents {
          color: #868782;
     }
`;

export const MobileTobaccoDirectionWrap = styled.div`
     box-sizing: border-box;
     position: fixed;
     display: flex;
     flex-direction: column;

     align-items: flex-end;
     z-index: 500;
     top: 37px;
     right: 90px;

     width: 400px;
     height: 230px;
     border-radius: 8px;
     background: #ffffff;
     box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
     padding: 24px 0px;

     div {
          box-sizing: border-box;
     }

     .top {
          padding: 0px 16px 8px;
          color: #3d4043;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
     }

     .alert {
          padding-left: 20px;
          width: 100%;
          text-align: left;
          color: #868782;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
     }
`;

export const MobileTobaccoDirectionInputWrap = styled.div`
     display: flex;
     width: 100%;
     flex-direction: column;
     gap: 10px;
`;

export const MobileTobaccoDirectionActiveWrap = styled.div`
     width: 100%;
     height: 30px;
     border: 1px solid #e6e6e6;
     border-radius: 8px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-right: 10px;
`;

export const MobileTobaccoDirectionInactive = styled.div`
     width: 100%;
     height: 30px;
     border: 1px solid #e6e6e6;
     border-radius: 8px;
     background-color: red;
`;

export const MobileTobaccoDirectionBtnWrap = styled.div`
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding-top: 12px;
     cursor: pointer;

     div {
          color: #fff;

          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.12px;
          background-color: #216cf0;
          width: 140px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
     }
`;

export const TobaccoSearchDrawerContents = styled.div`
     height: 60vh;
     overflow: auto;
     padding: 20px;
`;

export const TobaccoSearchResultWrap = styled.ul`
     width: 100%;
     height: 100%;
     overflow: auto;
`;

export const ItemTobaccoSearchResult = styled.li`
     width: 100%;
     box-sizing: border-box;
     display: flex;
     flex-direction: column;

     align-items: flex-start;
     padding: 20px 16px;
     font-size: 20px;
     border-bottom: 1px solid #e6e6e6;
     color: #1e2530;

     font-size: 12px;
     font-style: normal;
     font-weight: 700;
     line-height: 18px; /* 150% */
     letter-spacing: -0.24px;

     .address {
          font-size: 10px;
     }
     .contact-number {
          font-size: 10px;
     }
`;
