import styled from "@emotion/styled";

import { memo } from "react";

import PcFilterController from "../PcFilterControllerV2";

const PcStoreFilterTop = memo(({ sidebarOpen, onClose, codeFilter, onClickFilterButton, handleClickFilterReset }) => {
     return (
          <PcStoreFilterTopWrap sidebarOpen={sidebarOpen}>
               <DrawerTop>
                    <div onClick={onClose}>
                         <img src={"img/icons/svg/u_x.svg"} width={24} height={24} alt="filter-cancel" />
                    </div>
                    <DrawerTopRight onClick={handleClickFilterReset}>
                         <img src={"img/icons/svg/undo.svg"} width={24} height={24} alt="filter-remove" />
                         초기화
                    </DrawerTopRight>
               </DrawerTop>
               <PcFilterController
                    //@ts-ignore
                    codeFilter={codeFilter}
                    handleSetCodeFilter={onClickFilterButton}
               />
          </PcStoreFilterTopWrap>
     );
});

export default PcStoreFilterTop;

const PcStoreFilterTopWrap = styled.div`
     box-sizing: border-box;
     min-height: 300px;
     background: #ffffff;
     box-shadow:
          0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
          0px 2px 4px 0px rgba(0, 0, 0, 0.1);
     border-radius: 8px;
     border: 1.5px solid #dadce0;
     width: 644px;
     padding: 20px 10px 10px 10px;
     position: fixed;
     top: 85px;
     z-index: 3;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "465px" : "105px")};
`;

const DrawerTop = styled.div`
     display: flex;
     justify-content: space-between;
     align-items: center;

     div {
          cursor: pointer;
     }
`;

const DrawerTopRight = styled.div`
     display: flex;
     align-items: center;
     gap: 5px;
     color: #484848;
     text-align: right;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
`;
