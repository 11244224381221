import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
     postStoreDistanceApi,
     postStoreSearchApi,
     postTobaccoStoreSearchApi,
     postCaltexListApi,
     postStoreInfoApi,
     postTobaccoStoreDistanceApi,
     postTobaccoStoreInfoApi,
     getBookmarkListApi,
} from "../api/store";
import { CodeFilterType } from "../data/code";
import aixos, { AxiosError } from "axios";
import { StoreStateType, ReviewDateType, StoreDataType } from "../models/store";

export const postStoreDistance = createAsyncThunk(
     "postStoreDistance",
     async ({ lat, lng, distance, account }: { lat: string; lng: string; distance: string; account: string }, { dispatch, getState, rejectWithValue }) => {
          try {
               const result = await postStoreDistanceApi({
                    lat,
                    lng,
                    distance,
                    account,
               });

               return { recordset: result.data.data };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
               }
          }
     },
);

export const postTobaccoStoreDistance = createAsyncThunk(
     "postTobaccoStoreDistance",
     async (
          {
               lat,
               lng,
               distance,
               account,
               includeClosedWithin,
               includeSuspendedStore,
          }: {
               lat: string;
               lng: string;
               distance: string;
               account: string;
               includeClosedWithin: number;
               includeSuspendedStore: string;
          },
          { dispatch, getState, rejectWithValue },
     ) => {
          try {
               const result = await postTobaccoStoreDistanceApi({
                    lat,
                    lng,
                    distance,
                    account,
                    includeClosedWithin,
                    includeSuspendedStore,
               });

               return { recordset: result.data.data };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
               }
          }
     },
);

export const postCaltexList = createAsyncThunk(
     "postCaltexList",
     async ({ lat, lng, account }: { lat: string; lng: string; account: string }, { dispatch, getState, rejectWithValue }) => {
          try {
               const result = await postCaltexListApi({ lat, lng, account });
               return { recordset: result.data.data };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
               }
          }
     },
);

export const postFixedDistance = createAsyncThunk(
     "postFixedDistance",
     async (
          {
               lat,
               lng,
               distance,
               account,
               codeFilter,
          }: {
               lat: string;
               lng: string;
               distance: string;
               account: string;
               codeFilter: CodeFilterType | null;
          },
          { rejectWithValue },
     ) => {
          try {
               const result = await postStoreDistanceApi({
                    lat,
                    lng,
                    distance,
                    account,
               });

               let subCategory: any = {};

               // eslint-disable-next-line array-callback-return
               if (codeFilter) {
                    Object.entries(codeFilter).map(([key, value]) => {
                         if (value && value?.length > 0) {
                              value.forEach(v => {
                                   subCategory[v] = [];
                              });
                         }
                    });
               }

               result.data.data.forEach((store: StoreDataType) => {
                    if (store.dst !== 0) subCategory?.[store["sub_category_code"]]?.push(store);
               });

               return { result: result.data.data, filter: subCategory };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
               }
          }
     },
);

export const postStoreSearch = createAsyncThunk(
     "postStoreSearch",
     async ({
          storeName,
          account,
          offset,
          category,
     }: {
          storeName: string;
          account: string;
          offset: number;
          category: "편의점" | "슈퍼,마트" | "반찬가게" | "정육점" | "주유소";
     }) => {
          try {
               if (!storeName.trim()) {
                    return { searchStoreList: null };
               }

               const result = await postStoreSearchApi({
                    storeName,
                    account,
                    offset,
                    category,
               });

               if (result.data?.data) {
                    return { searchStoreList: result.data.data, offset };
               } else {
                    return { searchStoreList: null, offset };
               }
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
                    // rejectWithValue(err?.response?.data);
               }
          }
     },
);

export const postTobaccoStoreSearch = createAsyncThunk(
     "postTobaccoStoreSearch",
     async ({
          storeName,
          account,
          includeClosedWithin,
          includeSuspendedStore,
     }: {
          storeName: string;
          account: string;
          includeClosedWithin: number;
          includeSuspendedStore: string;
     }) => {
          try {
               if (!storeName.trim()) {
                    return { searchStoreList: null };
               }

               const result = await postTobaccoStoreSearchApi({
                    storeName,
                    account,
                    includeClosedWithin,
                    includeSuspendedStore,
               });

               if (result.data?.data) {
                    return { tobaccoSearchList: result.data.data };
               } else {
                    return { tobaccoSearchList: null };
               }
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
                    // rejectWithValue(err?.response?.data);
               }
          }
     },
);

export const postStoreInfo = createAsyncThunk(
     "postStoreInfo",
     async ({ id, account, date }: { id: string; account: string; date: string }, { dispatch, getState, rejectWithValue }) => {
          try {
               const { store } = getState() as { store: StoreStateType };
               const prevReviewDate = store.reviewDate as ReviewDateType;
               const getReviewDate = date ? date : prevReviewDate;

               const result = await postStoreInfoApi({
                    id,
                    account,
                    date: getReviewDate,
               });

               return { storeInfo: result.data, getReviewDate };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
                    // rejectWithValue(err?.response?.data);
               }
          }
     },
);

export const postTobaccoStoreInfo = createAsyncThunk(
     "postTobaccoStoreInfo",
     async (
          {
               managementNumber,
               account,
          }: {
               managementNumber: string;
               account: string;
          },
          { dispatch, getState, rejectWithValue },
     ) => {
          try {
               const result = await postTobaccoStoreInfoApi({
                    managementNumber,
                    account,
               });

               return { tobaccoStoreInfo: result };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
                    // rejectWithValue(err?.response?.data);
               }
          }
     },
);

export const getBookmarkList = createAsyncThunk(
     "getBookmarkList",
     async (
          {
               account,
          }: {
               account: string;
          },
          { dispatch, getState, rejectWithValue },
     ) => {
          try {
               const result = await getBookmarkListApi({
                    account,
               });

               return { getBookmarkList: result };
          } catch (err) {
               const errors = err as Error | AxiosError;
               if (!aixos.isAxiosError(errors)) {
                    return;
                    // rejectWithValue(err?.response?.data);
               }
          }
     },
);

const initialState: StoreStateType = {
     status: null,
     searchLoading: false,
     storeList: null,
     searchStoreList: null,
     fixedStoreFilterList: null,
     storeInfo: null,
     storeReviewList: null,
     storekeywordStat: null,
     areaInfo: null,
     areaInfoDetail: null,
     reviewDate: "3 개월",
     activeStore: null,

     storeDirectionStartList: null,
     tobaccoStoreList: null,
     fixedTobaccoStoreFilterList: null,

     tobaccoStoreInfo: null,
     tobaccoSearchStoreList: null,
     tobaccoSearchLoading: false,
     tobaccoBookmarkedList: null,
};

export const storeSlice = createSlice({
     name: "store",
     initialState,
     reducers: {
          searchReset: (state: StoreStateType, action: PayloadAction) => {
               state.searchStoreList = null;
          },

          tobaccoSearchReset: (state: StoreStateType, action: PayloadAction) => {
               state.tobaccoSearchStoreList = null;
          },

          storeDetailReset: (state: StoreStateType) => {
               state.storeInfo = null;
               state.fixedStoreFilterList = null;
          },

          tobaccoStoreDetailReset: (state: StoreStateType) => {
               state.tobaccoStoreInfo = null;
               state.fixedStoreFilterList = null;
          },

          areaInfoReset: (state: StoreStateType) => {
               state.areaInfo = null;
               state.areaInfoDetail = null;
          },
     },
     extraReducers: {
          [postStoreDistance.pending.type]: () => {},
          [postStoreDistance.fulfilled.type]: (state, { payload }) => {
               state.storeList = payload.recordset;
          },
          [postStoreDistance.rejected.type]: () => {},

          [postTobaccoStoreDistance.pending.type]: () => {},
          [postTobaccoStoreDistance.fulfilled.type]: (state, { payload }) => {
               state.tobaccoStoreList = payload.recordset;
          },
          [postTobaccoStoreDistance.rejected.type]: () => {},

          [postFixedDistance.pending.type]: state => {
               state.fixedStoreFilterList = null;
          },
          [postFixedDistance.fulfilled.type]: (state, { payload }) => {
               state.storeList = payload?.result;
               state.fixedStoreFilterList = payload?.filter;
          },
          [postFixedDistance.rejected.type]: state => {
               state.fixedStoreFilterList = null;
          },
          [postStoreSearch.pending.type]: state => {
               state.searchLoading = true;
          },
          [postStoreSearch.fulfilled.type]: (state, { payload }) => {
               state.searchLoading = false;

               if (payload?.offset >= 30) {
                    state.searchStoreList = [
                         ...(state?.searchStoreList ? state?.searchStoreList : []),
                         ...(payload?.searchStoreList ? payload.searchStoreList : []),
                    ];
               } else {
                    state.searchStoreList = payload.searchStoreList;
               }
          },
          [postStoreSearch.rejected.type]: state => {
               state.searchLoading = false;
          },

          [postTobaccoStoreSearch.pending.type]: state => {
               state.tobaccoSearchLoading = true;
          },
          [postTobaccoStoreSearch.fulfilled.type]: (state, { payload }) => {
               state.tobaccoSearchLoading = false;
               state.tobaccoSearchStoreList = payload.tobaccoSearchList;
          },
          [postTobaccoStoreSearch.rejected.type]: state => {
               state.tobaccoSearchLoading = false;
          },

          [postStoreInfo.pending.type]: state => {
               state.storeReviewList = null;
               state.storekeywordStat = null;
          },
          [postStoreInfo.fulfilled.type]: (state, { payload }) => {
               state.status = payload.storeInfo.resStatus;
               state.storeReviewList = payload.storeInfo.data.review;
               state.storekeywordStat = payload.storeInfo.data.keywordStat;
               state.storeInfo = payload.storeInfo.data.place;
               state.reviewDate = payload.getReviewDate;
               state.areaInfo = payload.storeInfo.data.areaInfo;
               state.areaInfoDetail = payload.storeInfo.data.areaInfoDetail;
          },
          [postStoreInfo.rejected.type]: state => {
               state.status = null;
               state.storeReviewList = null;
               state.storekeywordStat = null;
               state.storeInfo = null;
               state.reviewDate = "3 개월";
               state.areaInfo = null;
               state.areaInfoDetail = null;
          },
          [getBookmarkList.pending.type]: () => {},
          [getBookmarkList.fulfilled.type]: (state, { payload }) => {
               state.tobaccoBookmarkedList = payload.getBookmarkList.data.data;
          },
          [postStoreInfo.rejected.type]: () => {},

          [postTobaccoStoreInfo.pending.type]: state => {
               state.tobaccoStoreInfo = null;
          },
          [postTobaccoStoreInfo.fulfilled.type]: (state, { payload }) => {
               state.status = payload.tobaccoStoreInfo.resStatus;
               state.tobaccoStoreInfo = payload.tobaccoStoreInfo.data;
          },
          [postStoreInfo.rejected.type]: state => {
               state.tobaccoStoreInfo = null;
          },
     },
});

export const { searchReset, storeDetailReset, areaInfoReset, tobaccoStoreDetailReset, tobaccoSearchReset } = storeSlice.actions;

export default storeSlice.reducer;
