import styled from "@emotion/styled";

interface INearestStoreListWrap {
     bottom: number;
     left: number;
     isMobile?: boolean;
}
interface INewWrap {
     color: string;
}
interface IImageWrap {
     borderColor: string;
}

export const NewWrap = styled.div<INewWrap>`
     position: absolute;
     top: -5px;
     left: 16px;
     width: 14px;
     height: 14px;
     box-sizing: border-box;
     border-radius: 9999px;
     border: ${({ color }) => `1.5px solid ${color}`};
     color: #3d4043;
     text-align: center;

     font-size: 7px;
     font-style: normal;
     font-weight: 600;
     line-height: 12px; /* 171.429% */
     background-color: #fff;
`;

export const ImageWrap = styled.div<IImageWrap>`
     width: 29px;
     height: 29px;
     border-radius: 26px;
     position: relative;
     border: ${({ borderColor }) => `1px solid ${borderColor}`};
     display: flex;
     justify-content: center;
     align-items: center;

     .checked {
          img {
               width: 18px;
               height: 18px;
          }
     }

     .brand-other-icon {
          width: 14px !important;
          height: 14px !important;
     }

     img {
          width: 29px;
          height: 29px;
     }
`;

export const NearestStoreListWrap = styled.div<INearestStoreListWrap>`
     position: fixed;
     bottom: ${({ bottom }) => (bottom ? `calc(${bottom + 50}px);` : "0;")};
     left: ${({ left }) => (left ? `${left + 2}px;` : "0;")};
     margin-bottom: 8px;

     > ul {
          box-sizing: border-box;
          width: ${({ isMobile }) => (isMobile ? "180px" : "225px")};
          border-radius: 8px;
          max-height: 210px;
          overflow-y: auto;
          overflow-x: hidden;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.05);
          box-shadow:
               0px 1px 2px 0px rgba(0, 0, 0, 0.05),
               0px 1px 10px 0px rgba(0, 0, 0, 0.15);

          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }

          > li {
               box-sizing: border-box;
               display: flex;
               align-items: center;
               gap: 6px;
               padding: 8px;
               white-space: nowrap;
               border-bottom: 0.4px solid #bdbdbd;
               color: #3d4043;
               text-align: center;

               font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               cursor: pointer;
               &:last-child {
                    border-width: 0;
               }
          }
     }
`;
