import styled from "@emotion/styled";

interface IRoundButton {
     onClick: () => void;
     children: React.ReactNode;
     active: boolean;
}

const RoundButton = ({ onClick, children, active }: IRoundButton) => {
     return (
          <RoundBtn
               onClick={e => {
                    e.stopPropagation();
                    onClick();
               }}
               active={active}
          >
               {children}
          </RoundBtn>
     );
};

export default RoundButton;

interface IRoundBtn {
     active: boolean;
}

const RoundBtn = styled.div<IRoundBtn>`
     width: 36px;
     height: 36px;
     border-radius: 50%;
     background-color: ${({ active }) => (active ? "#D6E2FB" : "#B7B7B5")};
     display: flex;
     justify-content: center;
     align-items: center;
`;
