import styled from "@emotion/styled";

export const MobileHomeFilterWrap = styled.div`
     position: fixed;
     z-index: 2;
     display: flex;
     gap: 8px;
     top: 73px;
     left: 45px;
     width: calc(100% - 45px);
     overflow-x: auto;
     white-space: nowrap;
     scrollbar-width: none;
     margin-right: 30px;

     &::-webkit-scrollbar {
          display: none;
     }
`;

interface IItemMobileFilter {
     color: boolean;
     textColor: string;
}

export const ItemMobileFilter = styled.div<IItemMobileFilter>`
     box-sizing: border-box;
     padding: 9px 13px 9px 8px;
     display: flex;
     justify-content: center;
     align-items: center;
     background: #ffffff;
     border-radius: 8px;
     gap: 4px;
     height: 28px;

     border: ${({ color }) => `1px solid ${color}`};
     color: ${({ textColor }) => textColor};
     font-size: 12px;
     font-weight: 500;

     svg {
          width: 16px;
          height: 16px;
     }
`;
