import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";
import Home from "./pages/Home";

import Report from "./pages/report";
import SignIn from "./pages/signIn";
import { useIsAuthenticated } from "@azure/msal-react";
import Snackbar from "./components/snackbar";
// hook
import { OnIsTabletOrMobile } from "./hook/widthSize";
import MobileHome from "./pages/Home/MobileHome/index.jsx";
import TobaccoMobileHome from "./pages/tobacco/MobileHome/index";
import TobaccoPage from "./pages/tobacco";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";
import { IS_LOGGED_IN_BEFORE } from "./constants/flag";

LicenseInfo.setLicenseKey("09a113ecdba820f0fec7ea28633adbf3Tz04NjkzNCxFPTE3NDI2NDczNDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

function App() {
     const isAuthenticated = useIsAuthenticated();
     const isTabletOrMobile = OnIsTabletOrMobile();

     const [snackbarMessage, setSnackbarMessage] = useState<string>("");
     const [sidebarOpen, setSidebarOpen] = useState(true);

     const handleChangeSidebarOpen = () => {
          setSidebarOpen(prev => !prev);
     };

     useEffect(() => {
          if (!isAuthenticated) return;

          const isLoggedInBefore = localStorage.getItem(IS_LOGGED_IN_BEFORE);

          if (!isLoggedInBefore) {
               window.alert("인증정보가 만료되어 로그아웃 화면으로 이동합니다. 다시 접속해 주세요.");
               const msalInstance = new PublicClientApplication(msalConfig);
               msalInstance.logoutRedirect();
               return;
          }
          const parsedIsLoggedInBefore = JSON.parse(isLoggedInBefore);

          if (!parsedIsLoggedInBefore) {
               window.alert("인증정보가 만료되어 로그아웃 화면으로 이동합니다. 다시 접속해 주세요.");
               const msalInstance = new PublicClientApplication(msalConfig);
               msalInstance.logoutRedirect();
               return;
          }
     }, [isAuthenticated]);

     return (
          <Provider store={store}>
               {!isAuthenticated ? (
                    <SignIn />
               ) : (
                    <BrowserRouter>
                         <Routes>
                              {isTabletOrMobile ? (
                                   <>
                                        <Route path="/" element={<MobileHome setSnackbarMessage={setSnackbarMessage} />} />
                                        <Route path="/tobacco" element={<TobaccoMobileHome setSnackbarMessage={setSnackbarMessage} />} />
                                   </>
                              ) : (
                                   <>
                                        <Route path="/" element={<Home sidebarOpen={sidebarOpen} handleChangeSidebarOpen={handleChangeSidebarOpen} />} />
                                        <Route
                                             path="tobacco"
                                             element={<TobaccoPage sidebarOpen={sidebarOpen} handleChangeSidebarOpen={handleChangeSidebarOpen} />}
                                        />
                                   </>
                              )}

                              <Route path="/report" element={<Report />} />
                         </Routes>
                    </BrowserRouter>
               )}

               <Snackbar message={snackbarMessage} />
          </Provider>
     );
}

export default App;
