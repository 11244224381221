import { Typography } from "@mui/material";
import { MobileTobaccoDirectionWrap, DirectionResultWrap, MobileTobaccoDirectionBtnWrap } from "./PcDirection.styled";

const PcDirection = ({ directionStart, directionEnd, setDirectionStart, setDirectionEnd }) => {
     const handleClickDirection = () => {
          if (!directionStart.coordinate || !directionEnd.coordinate) {
               window.alert("출발지와 도착지를 모두 입력해 주세요");
               return;
          }

          if (
               directionStart.coordinate.x === directionEnd.coordinate.x &&
               directionStart.coordinate.y === directionEnd.coordinate.y &&
               directionStart.name === directionEnd.name
          ) {
               window.alert("출발지와 도착지는 동일할 수 없습니다");
               return;
          }

          if (directionStart.coordinate && directionEnd.coordinate) {
               const url = `http://map.naver.com/index.nhn?slng=${directionStart.coordinate.x}&slat=${directionStart.coordinate.y}&stext=${directionStart.name ?? "출발지"}&elng=${directionEnd.coordinate.x}&elat=${directionEnd.coordinate.y}&etext=${directionEnd.name ?? "도착지"}&menu=route&pathType=3`;
               window.open(url, "_blank");
          }
     };

     const handleChangeDirection = () => {
          const currentStart = { ...directionStart };
          const currentEnd = { ...directionEnd };

          setDirectionStart(currentEnd);
          setDirectionEnd(currentStart);
     };

     return (
          <>
               <MobileTobaccoDirectionWrap>
                    <div className="top">
                         <div>도보거리 계산하기</div>
                    </div>

                    <>
                         <DirectionResultWrap>
                              <div className="results">
                                   <div className="start">
                                        <div className="title">출발</div>
                                        <div className="contents">{directionStart.name ?? "지도에서 출발지를 선택해 주세요"}</div>
                                   </div>
                                   <div className="end">
                                        <div className="title">도착</div>
                                        <div className="contents">{directionEnd.name ?? "지도에서 도착지를 선택해 주세요"}</div>
                                   </div>
                              </div>

                              <div className="change-btn" onClick={handleChangeDirection}>
                                   <img src="img/icons/svg/u_exchange.svg" alt="exchange" width={22} />
                              </div>
                         </DirectionResultWrap>
                         <div className="alert">※점포가 아닌 경우는 마우스 우클릭으로 출발,도착지를 설정해 주세요</div>

                         <MobileTobaccoDirectionBtnWrap>
                              <div onClick={handleClickDirection}>길찾기</div>
                         </MobileTobaccoDirectionBtnWrap>
                    </>
               </MobileTobaccoDirectionWrap>
          </>
     );
};

export default PcDirection;
