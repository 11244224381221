import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getUserInfoApi } from "../api/store";
import aixos, { AxiosError } from "axios";

export const userInfo = createAsyncThunk("getUserInfo", async ({ account, kor_nm }: { account: string; kor_nm: string | undefined }, { rejectWithValue }) => {
     try {
          const result = await getUserInfoApi({ account, kor_nm });
          return { userInfo: result.data?.data };
     } catch (err) {
          const errors = err as Error | AxiosError;
          if (!aixos.isAxiosError(errors)) {
               return rejectWithValue("An error occurred");
          }
          return rejectWithValue(errors.response?.data);
     }
});

export interface UserInfoType {
     team_nm: string | null;
     kor_nm: string | null;
     emp_id: number | null;
     account: number | null;
     team_mng_yn: number | null;
     part_mng_yn: number | null;
     rfc_yn: string | null;
     rfc_adm_yn: string | null;
     sm_mng_yn: string | null;
     sm_fc_team_mng_yn: string | null;
     region_cd: string | null;
     review_yn: string | null;
     cigarette_license_updated: string | null;
     area_nm: string | null;
}

const initialState: UserInfoType = {
     team_nm: null,
     kor_nm: null,
     emp_id: null,
     account: null,
     team_mng_yn: null,
     part_mng_yn: null,
     rfc_yn: null,
     rfc_adm_yn: null,
     sm_mng_yn: null,
     sm_fc_team_mng_yn: null,
     region_cd: null,
     review_yn: null,
     cigarette_license_updated: null,
     area_nm: null,
};

export const userSlice = createSlice({
     name: "store",
     initialState,
     reducers: {
          userInfoReset: (state: UserInfoType, action: PayloadAction) => {
               state.team_nm = null;
               state.kor_nm = null;
               state.emp_id = null;
               state.account = null;
               state.team_mng_yn = null;
               state.part_mng_yn = null;
               state.rfc_yn = null;
               state.rfc_adm_yn = null;
               state.sm_mng_yn = null;
               state.sm_fc_team_mng_yn = null;
               state.region_cd = null;
               state.review_yn = null;
               state.cigarette_license_updated = null;
               state.area_nm = null;
          },
     },
     extraReducers: {
          [userInfo.pending.type]: () => {},
          [userInfo.fulfilled.type]: (state, { payload }) => {
               state.team_nm = payload.userInfo.team_nm;
               state.kor_nm = payload.userInfo.kor_nm;
               state.emp_id = payload.userInfo.emp_id;
               state.account = payload.userInfo.account;
               state.team_mng_yn = payload.userInfo.team_mng_yn;
               state.part_mng_yn = payload.userInfo.part_mng_yn;
               state.rfc_yn = payload.userInfo.rfc_yn;
               state.rfc_adm_yn = payload.userInfo.rfc_adm_yn;
               state.sm_mng_yn = payload.userInfo.sm_mng_yn;
               state.sm_fc_team_mng_yn = payload.userInfo.sm_fc_team_mng_yn;
               state.region_cd = payload.userInfo.region_cd;
               state.review_yn = payload.userInfo.review_yn;
               state.cigarette_license_updated = payload.userInfo.cigarette_license_updated;
               state.area_nm = payload.userInfo.area_nm;
          },
          [userInfo.rejected.type]: () => {},
     },
});

export const { userInfoReset } = userSlice.actions;

export default userSlice.reducer;
