import styled from "@emotion/styled";

export const SearchWrap = styled.div`
     z-index: 900;
     background-color: #fff;
     .search-header {
          box-sizing: border-box;
          position: fixed;
          top: 0px;
          display: flex;
          align-items: center;
          z-index: 900;
          padding: 0px 10px;
          background-color: #fff;
          height: 60px;
          width: 100%;

          .search-header-contents {
               width: 100%;
               display: flex;
               justify-content: space-between;
               border: 1px solid #dadce0;
               border-radius: 8px;
               overflow: hidden;
               height: 40px;
               align-items: center;
               box-sizing: border-box;
               padding: 0px 10px;
          }
     }

     .search-input-wrap {
          box-sizing: border-box;
          flex: 1;

          box-sizing: border-box;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;

          input {
               width: 100%;
               font-size: 16px;
               line-height: 16px;
               font-weight: 500;
               border-width: 0;
               flex: 1;

               &::placeholder {
                    color: var(--Grey-400, #71757a);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.14px;
               }
          }
          button {
               display: flex;
               align-items: center;
               justify-content: center;
               width: 38px;
               heght: 38px;
               background-color: #0c4284;

               flex-shrink: 0;

               > svg {
                    width: 18px;
                    height: 18px;
               }
               > svg path {
                    stroke: #fff;
               }
          }
     }

     #search-list {
          flex: 1;
          overflow-y: auto;
          height: calc(100dvh - 60px);

          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }
     }

     #search-list .search-item {
          box-sizing: border-box;
          cursor: pointer;
          padding: 10px 20px;
          border-bottom: 1px solid #e5e7eb;
     }

     #search-list .search-item-title {
          display: flex;
          align-items: flex-end;
          margin-bottom: 8px;
     }
     #search-list .search-item-title h2 {
          color: #1e2530;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 150% */
          letter-spacing: -0.24px;
     }
     #search-list .search-item-title .dst {
          padding-left: 0px;
          color: #206def;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          white-space: nowrap;
     }
     #search-list .search-item-title .dst svg {
          width: 14px;
          height: 17px;
          vertical-align: bottom;
     }

     #search-list .search-item-sub {
          display: flex;
          align-items: center;
     }

     #search-list .search-item-sub {
          color: #3c3c3c;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 180% */
     }

     #search-list .search-item-sub .phone {
          &:before {
               display: inline-block;
               width: 1px;
               height: 8px;
               background-color: #e2e2e2;
               margin: -1px 6px 1px;
               vertical-align: -1px;
               content: "";
          }
     }

     .address {
          color: #1e2530;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 180% */
     }
`;
