import styled from "@emotion/styled";

interface PcSidebarWrapProps {
     open?: boolean;
}

export const PcSidebarWrap = styled.div<PcSidebarWrapProps>`
     box-sizing: border-box;
     height: 100%;
     display: flex;
     font-family:
          "Pretendard Variable",
          Pretendard,
          -apple-system,
          BlinkMacSystemFont,
          system-ui,
          Roboto,
          "Helvetica Neue",
          "Segoe UI",
          "Apple SD Gothic Neo",
          "Noto Sans KR",
          "Malgun Gothic",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
          sans-serif !important;
     .main-bar {
          width: 90px;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          border-right: 1px solid #e8e8e8;

          h1 {
               align-items: center;
               display: flex;
               justify-content: center;
               padding: 30px 0 44px;
          }
          h1 img {
               width: 60px;
               cursor: pointer;
          }

          nav {
               flex: 1;
          }

          li {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 100%;
               height: 70px; /* 고정 높이 설정 */
               color: #71757a;
               text-align: center;

               font-size: 16px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               letter-spacing: -0.48px;
          }

          nav > ul li a,
          nav > ul li button {
               box-sizing: border-box;
               display: flex;
               width: 100%;
               margin-bottom: 30px;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               font-size: 14px;
               line-height: 25px;
               color: #0c4284;

               font-weight: 500;
               padding: 10px 0px;

               &.active {
                    background: #3871e0;
                    color: #fff;
                    border-radius: 8px;
                    box-shadow: 0px 8px 12px 0px rgba(111, 150, 227, 0.3);
                    /* 패딩 고정 */
                    svg path {
                         fill: #fff;
                         stroke: #fff;
                    }
                    svg ellipse {
                         fill: #fff;
                    }
               }
               &.sub-active {
                    cursor: no-drop;
               }
          }

          nav > ul li a svg,
          nav > ul li button svg {
               width: 20px;
               height: 20px;
          }

          .side-other {
               border-top: 1px solid #d9d9d9;
               height: 110px;

               width: 90px;
               display: flex;
               flex-direction: column;
               align-items: center;
               padding: 20px 0px 20px;
               gap: 12px;
               color: #3d4043;
               text-align: center;
               word-break: break-all;

               font-size: 16px;
               font-style: normal;
               font-weight: 700;
               line-height: 12px; /* 100% */
               letter-spacing: 0.12px;
               white-space: pre-line;

               .sign-out {
                    cursor: pointer;
               }
          }
          .side-other button:first-of-type {
          }

          .side-other svg {
               width: 30px;
               height: 30px;
          }
     }

     .sub-bar {
          position: fixed;
          background-color: #ffffff;
          left: 91px;
          box-sizing: border-box;
          height: 100%;
          transition: all 350ms;
          border-right: 1px solid #e6e6e6;
          ${({ open }) =>
               !open &&
               `min-width: 0;
	
			> div {
				width: 0;
				opacity: 0;
				padding: 0;
			}
		`}
     }

     .change-open-btn {
          cursor: pointer;
          position: fixed;
          top: 55%;
          left: 91px;
          width: 30px;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #e8e8e8;
          border-left: none;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          background-color: #ffffff;
          ${({ open }) =>
               open &&
               `left: 452px;	
		`}
     }
`;
