import styled from "@emotion/styled";

interface IInfoKeywordBarWrap {
     per?: number;
}

export const InfoKeywordBarWrap = styled.li<IInfoKeywordBarWrap>`
     position: relative;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 10px 15px;
     border: 1px solid #d1d5db;
     border-radius: 8px;
     margin-bottom: 5px;
     overflow: hidden;

     &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: ${({ per }) => (per ? `${per}%;` : "0;")};
          height: 41px;
          background-color: #dae9fc;
          z-index: 10px;
     }

     &:last-child {
          margin-bottom: 0;
     }
     > p {
          font-weight: 500;
          font-size: 14px;
          z-index: 30;
     }
     > span {
          color: #1473e6;
          font-weight: 600;
          font-size: 14px;
          z-index: 30;
     }
`;

interface IInfobarWrap {
     sidebarOpen: boolean;
}

export const DetailModeTop = styled.div`
     padding-bottom: 14px;
     padding-left: 18px;

     img {
          cursor: pointer;
     }
`;

export const StoreDetailWrap = styled.div<IInfobarWrap>`
     z-index: 1001;
     background-color: #f1f4f9;
     padding: 18px 0px;
     position: fixed;
     display: flex;
     flex-direction: column;

     top: 30px;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "490px" : "130px")};
     width: 360px;
     height: calc(100vh - 90px);
     max-height: 900px;
     border-radius: 10px;
     box-shadow:
          0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
          0px 2px 4px 0px rgba(0, 0, 0, 0.1);

     .active-tobacco-store-info {
          padding: 0px 18px;

          font-size: 12px;
     }
     h2 {
          color: #1e2530;
          word-break: keep-all;

          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 100% */
          padding-bottom: 12px;
     }

     .active-tobacco-store-details {
          color: #71757a;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
     }
`;

export const VotedkeywordsWrap = styled.ul`
     li {
          padding: 5px 20px;
          background: #ffffff;
          border: 0.5px solid #e2e5e9;
          box-sizing: border-box;
          box-shadow: 0px 2px 5px rgba(31, 41, 55, 0.15);
          border-radius: 8px;
          color: #374151;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
     }
`;
