import styled from "@emotion/styled";
import { DISTANCE_100, DISTANCE_1000, DISTANCE_150, DISTANCE_300, DISTANCE_50, DISTANCE_500, DISTANCE_75, INITIAL_LAT, INITIAL_LNG } from "../../constants/map";
import { useState } from "react";

const { naver } = window;

const distList = [
     { text: "150M", value: DISTANCE_150 },
     { text: "300M", value: DISTANCE_300 },
     { text: "500M", value: DISTANCE_500 },
     { text: "1KM", value: DISTANCE_1000 },
];

const circleDistList = [
     { text: "50M", value: DISTANCE_50 },
     { text: "75M", value: DISTANCE_75 },
     { text: "100M", value: DISTANCE_100 },
     { text: "300M", value: DISTANCE_300 },
];

const PcTobaccoRightButtons = ({
     filterOption,
     updateTobaccoStoreList,
     map,
     onClickDistanceButton,
     onClickCircleDistanceButton,
     handleChangeDirectionStatus,
}) => {
     const [openDistance, setOpenDistance] = useState(false);
     const [openCircleDistance, setCircleDistance] = useState(false);

     const handleClickCurrent = () => {
          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);

                         map.current.setCenter(golocationPosition);
                         updateTobaccoStoreList(position.coords.latitude, position.coords.longitude, filterOption.distance, filterOption.includeClosedWithin);
                    },
                    error => {
                         window.alert("위치 정보 미동의: 현재 위치를 확인할 수 없습니다.");
                         return;
                         // console.log("위치 정보 미동의");
                         // map.current.setCenter(naver.maps.LatLng(INITIAL_LAT, INITIAL_LNG));

                         // updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, filterOption.distance, filterOption.includeClosedWithin);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               window.alert("위치 정보 미동의: 현재 위치를 확인할 수 없습니다.");
               return;
               // console.error("GPS를 지원하지 않습니다");
               // map.current.setCenter(naver.maps.LatLng(INITIAL_LAT, INITIAL_LNG));

               // updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, filterOption.distance, filterOption.includeClosedWithin);
          }
     };

     return (
          <MobileStoreRightButtonsWrap>
               <IconWraps onClick={handleChangeDirectionStatus}>
                    <img width={18} src="img/icons/svg/u_direction.svg" alt="ruler" />
                    <div>도보거리</div>
               </IconWraps>
               {!openDistance && (
                    <IconWraps onClick={() => setOpenDistance(true)}>
                         <img src="img/icons/svg/meter.svg" alt="goToCurrentPosition" />
                         <div>조회범위</div>
                    </IconWraps>
               )}
               {openDistance && (
                    <IconDistanceLarge>
                         <div className="large-top" onClick={() => setOpenDistance(false)}>
                              <img src="img/icons/svg/meter.svg" alt="goToCurrentPosition" />
                              <div>조회범위</div>
                         </div>
                         <div className="distance-wrap">
                              {distList.map(el => (
                                   <div
                                        onClick={onClickDistanceButton(el.value)}
                                        className={`distance ${filterOption.distance === el.value ? "active" : ""}`}
                                        key={`distance_${el.text}`}
                                   >
                                        {el.text}
                                   </div>
                              ))}
                         </div>

                         <div className="large-bottom" onClick={() => setOpenDistance(false)}>
                              <img src="img/icons/svg/arrow-up.svg" alt="arrow-top" />
                         </div>
                    </IconDistanceLarge>
               )}
               {!openCircleDistance && (
                    <IconWraps onClick={() => setCircleDistance(true)}>
                         <img src="img/icons/svg/meter.svg" alt="goToCurrentPosition" />
                         <div>점포반경</div>
                    </IconWraps>
               )}
               {openCircleDistance && (
                    <IconDistanceLarge>
                         <div className="large-top" onClick={() => setCircleDistance(false)}>
                              <img src="img/icons/svg/meter.svg" alt="goToCurrentPosition" />
                              <div>점포반경</div>
                         </div>
                         <div className="distance-wrap">
                              {circleDistList.map(el => (
                                   <div
                                        onClick={onClickCircleDistanceButton(el.value)}
                                        className={`distance ${filterOption.circleDistance === el.value ? "active" : ""}`}
                                        key={`distance_${el.text}`}
                                   >
                                        {el.text}
                                   </div>
                              ))}
                         </div>

                         <div className="large-bottom" onClick={() => setCircleDistance(false)}>
                              <img src="img/icons/svg/arrow-up.svg" alt="arrow-top" />
                         </div>
                    </IconDistanceLarge>
               )}

               <IconWraps onClick={handleClickCurrent}>
                    <img src="img/icons/svg/current.svg" width={18} alt="goToCurrentPosition" />
                    <div>내위치</div>
               </IconWraps>
          </MobileStoreRightButtonsWrap>
     );
};

export default PcTobaccoRightButtons;

const IconDistanceLarge = styled.div`
     .distance-wrap {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
     }

     .distance {
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 0.4px solid #b7b7b5;
     }

     .active {
          background: #f1f4f9;
          font-weight: bold;
     }

     box-sizing: border-box;
     padding: 8px 0px;
     color: #484848;
     text-align: center;

     font-size: 12px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     width: 58px;
     height: 203px;
     background: #fff;
     border-radius: 99999px;
     background: #fff;
     box-shadow: 0px 0.5px 1.5px 0px rgba(0, 0, 0, 0.35);
     display: flex;
     flex-direction: column;

     .large-top {
          cursor: pointer;
          border-bottom: 0.4px solid #b7b7b5;
          padding-bottom: 8px;
     }
     .large-bottom {
          cursor: pointer;
          border-top: 0.4px solid #b7b7b5;
          padding: 8px 0px;
     }
`;

const MobileStoreRightButtonsWrap = styled.div`
     position: fixed;
     top: 34px;
     right: 20px;
     z-index: 3;
     gap: 6px;
     display: flex;
     flex-direction: column;
`;

const IconWraps = styled.div`
     cursor: pointer;
     width: 58px;
     height: 58px;
     background-color: #ffffff;
     border-radius: 50%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     box-shadow: 0px 0.5px 1.5px 0px rgba(0, 0, 0, 0.35);
     color: #484848;
     text-align: center;

     font-size: 12px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
`;
