import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../auth/authConfig";

let flag = false;

const baseApiUrl =
     window.location.origin === "https://storemap.gsrdt.com" ? "https://gsr-dt-api-p002.azurewebsites.net" : (window.location.origin === "https://storemap.gsrdt-dev.com" ? "https://gsr-dt-app-d002.azurewebsites.net" : "http://localhost:8080");

const NewApi = axios.create({
     baseURL: baseApiUrl,
     withCredentials: true,
});

NewApi.defaults.withCredentials = true;

NewApi.interceptors.request.use(
     function (config) {
          const token = localStorage.getItem("idToken");

          if (token) {
               config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
     },
     function (error) {
          return Promise.reject(error);
     },
);

NewApi.interceptors.response.use(
     function (response) {
          return response;
     },
     function (error) {
          if (!flag) {
               window.alert("인증정보가 만료되어 로그아웃 화면으로 이동합니다. 다시 접속해 주세요.");
               const msalInstance = new PublicClientApplication(msalConfig);
               flag = true;
               msalInstance.logoutRedirect();
          }

          return Promise.reject(error);
     },
);

NewApi.defaults.headers.post["Content-Type"] = "application/json";

export default NewApi;
