import { useLocation } from "react-router-dom";
import { ItemMobileBottomMenu, MobileBottomMenuWrap } from "./MobileBottomMenu.styled";
import { Link } from "react-router-dom";
import IconStore from "./IconComponents/IconStore";
import IconCigarette from "./IconComponents/IconCigarette";
import IconReviews from "./IconComponents/IconReveiws";
import { useSelector } from "react-redux";

const MENU = [
     { name: "점포 지도", needPermission: false, linkTo: "/" },
     { name: "담배권 지도", needPermission: true, linkTo: "/tobacco" },
     { name: "담당 점포 리뷰", needPermission: true, condition: "review", linkTo: "/report" },
];

const MobileBottomMenu = () => {
     const userInfoData = useSelector((state: any) => state.user);

     const location = useLocation();
     return (
          <MobileBottomMenuWrap>
               {MENU.filter(el => {
                    if (!el.needPermission) return true;

                    if (el.condition === "review") {
                         return userInfoData?.review_yn === "Y";
                    }

                    return (userInfoData?.sm_fc_team_mng_yn ?? "N") === "N" && (userInfoData?.sm_mng_yn ?? "N") === "N";
               }).map(el => {
                    const active = location.pathname === el.linkTo;
                    const iconComponent =
                         el.linkTo === "/" ? (
                              <IconStore active={active} />
                         ) : el.linkTo === "/tobacco" ? (
                              <IconCigarette active={active} />
                         ) : (
                              <IconReviews active={active} />
                         );

                    return (
                         <Link key={el.name} to={el.linkTo}>
                              <ItemMobileBottomMenu active={active}>
                                   {iconComponent}
                                   {el.name}
                              </ItemMobileBottomMenu>
                         </Link>
                    );
               })}
          </MobileBottomMenuWrap>
     );
};

export default MobileBottomMenu;
