const { naver } = window;

export const tobaccoMarkerOverlap = (tobaccoStoreList, map) => {
     const proj = map.current.getProjection();
     const overlap = [];

     tobaccoStoreList
          ?.sort((a, b) => {
               if (a?.detailedBusinessStatusName === "폐업처리" && b?.detailedBusinessStatusName !== "폐업처리") {
                    return -1;
               }
               if (a?.detailedBusinessStatusName !== "폐업처리" && b?.detailedBusinessStatusName === "폐업처리") {
                    return 1;
               }
               return 0;
          })
          .forEach(store => {
               const coord = new naver.maps.LatLng(store.ylatitude, store.xlongitude);
               const markerOffset = proj.fromCoordToOffset(coord);

               if (overlap.length <= 0) {
                    overlap.push({
                         ...store,
                         coord,
                         offset: markerOffset,
                         child: [],
                    });
               } else {
                    const resultIndex = overlap.findIndex(m => Math.abs(m.offset.x - markerOffset.x) <= 35 && Math.abs(m.offset.y - markerOffset.y) <= 35);

                    if (resultIndex !== -1) {
                         overlap[resultIndex].child.push({ ...store });
                    } else {
                         overlap.push({
                              ...store,
                              coord,
                              offset: markerOffset,
                              child: [],
                         });
                    }
               }
          });

     return overlap;
};

export const createMarkerEventHandlers = (marker, store, onClickStore, deleteCircle, map, activeStore) => {
     const mouseOverHandler = naver.maps.Event.addListener(marker, "mouseover", function (e) {
          if (store?.managementNumber) {
               marker.eventTarget.classList.add("hover");
          }
     });

     const mouseOutHandler = naver.maps.Event.addListener(marker, "mouseout", () => {
          marker.eventTarget.classList.remove("hover");
     });

     const clickHandler = naver.maps.Event.addListener(marker, "click", e => {
          if (store.child.length <= 0) {
               onClickStore(store);
          } else {
               deleteCircle();

               map.current.setCenter(naver.maps.LatLng(store.ylatitude, store.xlongitude));

               const markerElement = document.getElementById(`${store.managementNumber}`);
               if (activeStore?.data) {
                    markerElement && markerElement.parentElement.classList.remove("active");
               }

               onClickStore(store);
          }
     });

     return {
          mouseOverHandler,
          mouseOutHandler,
          clickHandler,
     };
};
