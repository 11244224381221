import { IMAGES_CODE } from "../data/code/index";

const { naver } = window;

export const markerImage = (store, activeStore, userInfoData) => {
     const { category, sub_category, name, dst, child, rfc_region_cd, oper_region_cd, area_nm } = store;
     const { team_mng_yn, rfc_yn, rfc_adm_yn, region_cd, area_nm: area_nm_yn } = userInfoData;

     const categoryConfig = {
          편의점: { className: "store", image: IMAGES_CODE[sub_category] },
          "슈퍼,마트": {
               className: "mart-station",
               image: IMAGES_CODE[sub_category],
          },
          주유소: { className: "gas-station", image: IMAGES_CODE[sub_category] },
          반찬가게: { className: "side-dish-station", image: null },
          정육점: { className: "meat", image: null },
     };

     const { className, image } = categoryConfig[category] || {
          className: "",
          image: null,
     };

     const markerDefault = ({ className, image }) => {
          if (area_nm?.includes(area_nm_yn)) {
               className = className + " rfc";
          } else if (team_mng_yn === "Y" && oper_region_cd === region_cd) {
               className = className + " rfc";
          } else if (rfc_yn === "Y" && rfc_region_cd === region_cd) {
               className = className + " rfc";
          } else if (rfc_adm_yn === "Y" && rfc_region_cd !== null) {
               className = className + " rfc";
          }

          const imgTag = image ? `<img src=${image} alt=${name}/>` : `<div class="marker-none-img"><img src="img/icons/svg/color_${className}.svg"/></div>`;

          const dstText = dst !== 0 ? `[ ${dst.toLocaleString()}m ]` : "";

          const childCount = child.length > 0 ? `<span class="marker-button-count">+${child.length}</span>` : "";

          return `<div class="marker-wrap ${activeStore?.open && store.id === activeStore.data?.id ? "active" : ""}">
                    <button id="${name}" aria-label="${name}" class="marker-button ${className}">
                        ${imgTag}
                        <p class="marker-button-name">${name} ${dstText}</p>
                        ${childCount}
                    </button>
                </div>
                 <div class="marker-dot ${className}"/>      
                `;
     };

     return className ? markerDefault({ className, image }) : `<div></div>`;
};

export const tobaccoMarkerImage = (tobaccoStore, activeStore) => {
     const { managementNumber, businessName, businessStatusCode, child, distance, recentMonthUpdated } = tobaccoStore;

     const classData = managementNumber === null ? "place" : businessStatusCode === "01" ? "open" : businessStatusCode === "02" ? "suspended" : "closed";

     const svgName = managementNumber === null ? "edit_white" : businessStatusCode === "01" ? "check" : businessStatusCode === "02" ? "suspended" : "closed";
     const isNewStore = recentMonthUpdated === "Y";

     const markerDefault = ({ className }) => {
          const imgTag = `<div class="marker-none-img">
          <img src="img/icons/svg/u_${svgName}.svg"  alt="${className}"/></div>`;

          const dstText = distance !== 0 ? `[ ${distance.toLocaleString()}m ]` : "";
          const childCount = child.length > 0 ? `<span class="marker-button-count">+${child.length}</span>` : "";
          const newStore = isNewStore && child.length === 0 ? `<span class="marker-button-new">NEW</span>` : "";

          const nameInfo = managementNumber
               ? `<p class="marker-button-name">
                    ${businessName} ${dstText}
               </p>`
               : "";

          return `

          <div class="marker-wrap ${managementNumber && activeStore?.open && activeStore.data?.managementNumber === managementNumber ? "active" : ""}">
                    <button ${managementNumber ? `id="${managementNumber}"` : ""} label=${managementNumber} class="marker-button ${className}">
                        ${imgTag}
                         ${managementNumber ? nameInfo : ""}
                        ${childCount}
                        ${newStore}
                    </button>
                    </div>
               <div class="marker-dot ${className}"/>            
    
                    `;
     };

     return markerDefault({ className: classData });
};

export const getStartMarkerIcon = () => {
     return {
          content: '<div style="position:relative""><div><img src="img/icons/direction-start.png" style="width:47px; height:58.75px;"/></div><div style="width:37px; display:flex; justify-content:center; align-items:center; height:37px; background:#216CF0;font-size:13px; border-radius:50%; color:white; position:absolute; top:5px; left:5px;">출발</div></div>',
          size: new naver.maps.Size(30, 30),
          anchor: new naver.maps.Point(20, 30),
     };
};

export const getEndMarkerIcon = () => {
     return {
          content: '<div style="position:relative""><div><img src="img/icons/direction-end.png" style="width:47px; height:58.75px;"/></div><div style="width:37px; display:flex; justify-content:center; align-items:center; height:37px; background:#FF0000;font-size:13px; border-radius:50%; color:white; position:absolute; top:5px; left:5px;">도착</div></div>',
          size: new naver.maps.Size(30, 30),
          anchor: new naver.maps.Point(20, 30),
     };
};

export const handleStartMarkerDelete = (startMarkerRef, markerInfoWindowRef) => {
     if (startMarkerRef.current) {
          startMarkerRef.current.setMap(null);

          startMarkerRef.current = null;
          markerInfoWindowRef.current = null;
     }
};

export const handleEndMarkerDelete = (endMarkerRef, markerInfoWindowRef) => {
     if (endMarkerRef.current) {
          endMarkerRef.current.setMap(null);

          endMarkerRef.current = null;
          markerInfoWindowRef.current = null;
     }
};
