import styled from "@emotion/styled";

interface IDirectionButton {
     text: string;
     onClick: () => void;
}

const DirectionButton = ({ text, onClick }: IDirectionButton) => {
     return (
          <ItemDirection
               onClick={e => {
                    e.stopPropagation();
                    onClick();
               }}
          >
               {text}
          </ItemDirection>
     );
};

export default DirectionButton;

const ItemDirection = styled.div`
     cursor: pointer;
     width: 62px;
     height: 38px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 999px;
     border: 1px solid #dadce0;
     background: #216cf0;
     box-shadow: 0px 0px 40px 0px rgba(179, 198, 207, 0.5);
     color: #f1f4f9;
     text-align: center;
     -webkit-text-stroke-width: 1;
     -webkit-text-stroke-color: #f1f4f9;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 12px; /* 100% */
`;
