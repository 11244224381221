import styled from "@emotion/styled";

export const MobileHomeTobaccoFilterWrap = styled.div`
     position: fixed;
     z-index: 2;
     display: flex;
     gap: 8px;
     top: 34px;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "465px" : "105px")};

     overflow-x: auto; /* 스크롤 자동 생성 */
     white-space: nowrap; /* 자식 요소가 줄바꿈되지 않도록 설정 */
     scrollbar-width: thin;

     &::-webkit-scrollbar {
          height: 8px;
     }
     &::-webkit-scrollbar-thumb {
          background: #8818;
          border-radius: 4px;
     }
`;

export const ItemMobileFilter = styled.div`
     cursor: pointer;
     box-sizing: border-box;
     padding: 9px 13px 9px 8px;
     display: flex;
     justify-content: center;
     align-items: center;
     background: #ffffff;
     border-radius: 8px;
     gap: 4px;
     height: 42px;

     border: ${({ color }) => `1px solid ${color}`};
     color: ${({ textColor }) => textColor};
     font-size: 18px;
     font-weight: 500;

     svg {
          width: 21px;
          height: 21px;
          padding-bottom: 3px;
     }
`;
