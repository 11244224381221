import styled from "@emotion/styled";
import { ReactComponent as Close } from "../../assets/close.svg";
import IconStar from "../../assets/svgComponents/IconStar";
import DirectionButton from "../buttons/DirectionButton";
import RoundButton from "../buttons/RoundButton";

const MobileActiveSelectedPosition = ({
     directionDrawerOpen,
     selectedPosition,
     showPlaceChangeMessage,
     selectedPositionInfo,
     handleCloseSelectedPositionInfo,
     setDirectionStart,
     setDirectionEnd,
     handleClickFavoriteBtn,
     handleClickAddressBookmark,
}) => {
     const locationInfo = selectedPositionInfo.data.data;

     const address = locationInfo?.fullRoadAddress ?? locationInfo.fullLocationAddress;

     const handleClickDirection = type => {
          const directionInfo = {
               coordinate: { x: selectedPosition?.x, y: selectedPosition?.y },
               name: address,
               isPlace: true,
          };

          if (type === "START") {
               setDirectionStart(directionInfo);
          }
          if (type === "END") {
               setDirectionEnd(directionInfo);
          }
     };

     return (
          <MobileActiveStoreInfoWrap directionDrawerOpen={directionDrawerOpen}>
               <div>
                    <div className="active-store-name">
                         <h2>{address}</h2>
                    </div>
                    <p className="active-store-address">{address}</p>
                    <BottomBtnsWrap>
                         {!showPlaceChangeMessage && (
                              <>
                                   <LeftBtns>
                                        <RoundButton
                                             onClick={() => {
                                                  handleClickAddressBookmark(locationInfo);
                                             }}
                                             active={false}
                                        >
                                             <IconStar lineColor="#F1F4F9" />
                                        </RoundButton>
                                   </LeftBtns>

                                   <RightBtns>
                                        <DirectionButton text="출발" onClick={() => handleClickDirection("START")} />
                                        <DirectionButton text="도착" onClick={() => handleClickDirection("END")} />
                                   </RightBtns>
                              </>
                         )}
                    </BottomBtnsWrap>
               </div>

               <button
                    className="active-store-info-close"
                    onClick={e => {
                         e.preventDefault();
                         handleCloseSelectedPositionInfo();
                    }}
               >
                    <Close />
               </button>
          </MobileActiveStoreInfoWrap>
     );
};

export default MobileActiveSelectedPosition;

const LeftBtns = styled.div`
     display: flex;
     gap: 8px;
`;

const RightBtns = styled.div`
     display: flex;
     gap: 5px;
`;

const BottomBtnsWrap = styled.div`
     display: flex;
     width: 100%;
     padding-top: 10px;
     justify-content: space-between;
     gap: 6px;
     min-height: 44px;
`;

const MobileActiveStoreInfoWrap = styled.div`
     box-sizing: border-box;
     position: absolute;
     bottom: ${({ directionDrawerOpen }) => (directionDrawerOpen ? "220px" : "80px")};
     left: 0;
     width: 100%;
     padding: 0 10px 25px;
     z-index: 500;
     > div {
          box-sizing: border-box;
          width: 100%;
          padding: 16px 14px 18px;
          box-shadow: rgb(0 0 0 / 15%) 3px 3px 15px;
          border-radius: 10px;
          background-color: #fff;

          .active-store-name {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin-bottom: 8px;
          }

          .active-store-name h2 {
               color: #1e2530;
               font-size: 14px;
               font-style: normal;
               font-weight: 700;
               line-height: 18px; /* 150% */
               letter-spacing: -0.24px;
          }

          .active-store-details {
               display: flex;
               align-items: center;
               color: #71757a;
               font-size: 12px;
               font-style: normal;
               font-weight: 500;
               line-height: 16px; /* 160% */
          }

          .active-store-details p {
               &:nth-of-type(2)::before {
                    display: inline-block;
                    width: 1px;
                    height: 8px;
                    background-color: rgb(226, 226, 226);
                    margin: -1px 6px 1px;
                    vertical-align: -1px;
                    content: "";
               }
          }
     }
     .active-store-address {
          color: #71757a;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 160% */
     }

     .active-store-info-close {
          position: absolute;
          top: 11.5px;
          right: 35px;
          padding: 6px;

          > svg {
               width: 14px;
               height: 14px;
          }
     }
`;
