import styled from "@emotion/styled";

export const SideBarStoreListWrap = styled.div`
    flex: 1;
    box-sizing:border-box;
    height: calc(100% - 138px);
    padding-top:15px;
    overflow-y: auto;
    margin-right: -20px;
    width:360px;

    

    li {
      list-style: none;
      color: #1E2530;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; 
      letter-spacing: -0.28px;
    }

    li:hover{
      background:#F0F3F8;
    }

    .active{
      background:#F0F3F8;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: #ced4da;

      &:hover {
        background-color: #adb5bd;
      }
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }
  }

  .search-item {
    cursor: pointer;
    padding: 12px 20px 12px 26px !important;
    border-bottom: 1px solid #DADCE0;
  }
  /* .search-item:hover {
		background: #f7f9fa;
	} */
  /* .search-item:last-child {
			border-width: 0;
		} */
  .search-item-title {
    display: flex;
    align-items: flex-end;

  }
  .search-item-title h2 {
    font-size: 16px;
    font-weight: bold;
    margin-right: 8px;
    line-height: 18px;
  }
  .search-item-title .dst {
    font-size: 12px;
    font-weight:500;
    color: #3e85e8;
    white-space: nowrap;
  }
  .search-item-title .dst svg {
    width: 13px;
    height: 17px;
    vertical-align: bottom;
  }

  .search-item-sub {
    color: #3C3C3C;
    font-size: 13px;
    font-weight: 500;
  }
  .search-item-sub .category {
    display: inline-block;
    color: #777777;
  }

  .search-item-sub .phone {
    color: #777777;
    &:before {
      display: inline-block;
      width: 1px;
      height: 8px;
      background-color: #e2e2e2;
      margin: -1px 6px 1px;
      vertical-align: -1px;
      content: '';
    }
  }
    .address {
    font-size:13px;
    font-weight:400;

  }
`;
