import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import styled from "styled-components";
import { IS_LOGGED_IN_BEFORE } from "../../constants/flag";

export const SignInButton = () => {
     const { instance } = useMsal();

     const handleLogin = async () => {
          try {
               const result = await instance.loginPopup(loginRequest);
               localStorage.setItem("idToken", result.idToken);
               localStorage.setItem(IS_LOGGED_IN_BEFORE, true);
          } catch (error) {
               console.error("Login Canceled:", error);
          }
     };

     return (
          <SignInButtonWrap onClick={handleLogin}>
               <p>로 그 인</p>
          </SignInButtonWrap>
     );
};

const SignInButtonWrap = styled.button`
     display: flex;
     align-items: center;
     justify-content: center;
     width: 100%;
     max-width: 400px;
     padding: 16px 20px;
     background-color: #0472ba;

     p {
          color: #fff;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
     }
`;
