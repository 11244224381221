import { use100vh } from "react-div-100vh";

import { useSelector } from "react-redux";
import {
     TobaccoDetailWrap,
     TobaccoItemData,
     TobaccoManagementContentsWrap,
     TobaccoManagementInfoTop,
     TobaccoManagementInfoWrap,
} from "./MobileTobaccoDetail.styled.ts";
import { DetailModeTop } from "./MobileStoreDetail.styled";
import styled from "@emotion/styled";
import { useMemo } from "react";
import IconStarFill from "../../assets/svgComponents/IconStarFill.jsx";
import IconStar from "../../assets/svgComponents/IconStar.jsx";
import DirectionButton from "../buttons/DirectionButton.tsx";
import RoundButton from "../buttons/RoundButton.tsx";

const TobaccoMobileDetail = ({
     handleChangeMoveStoreInfo,
     handleClickFavoriteBtn,
     activeStore,
     setDirectionStart,
     setDirectionEnd,
     infoEl,
     handleCloseDetailMode,
}) => {
     const { tobaccoStoreInfo, tobaccoBookmarkedList } = useSelector(state => state.store);

     const { data } = activeStore;

     const { businessName, contactNumber, businessStatusCode, detailedBusinessStatusName, fullLocationAddress, fullRoadAddress } = data;

     const address = fullRoadAddress ?? fullLocationAddress;
     const height = use100vh();

     const detailStoreInfo = tobaccoStoreInfo.data;

     const approvalDate = (detailStoreInfo?.approvalDate ?? "").replaceAll("-", ".");
     const closureDate = (detailStoreInfo?.closureDate ?? "").replaceAll("-", ".");
     const suspensionStartDate = (detailStoreInfo?.suspensionStartDate ?? "").replaceAll("-", ".");
     const suspensionEndDate = (detailStoreInfo?.suspensionEndDate ?? "").replaceAll("-", ".");

     const addressChangeHistories = useMemo(() => {
          if (!detailStoreInfo?.storeAddressHistory || detailStoreInfo?.storeAddressHistory.length === 0) {
               return { showHistory: false };
          }

          const originAddress = detailStoreInfo?.storeAddressHistory.find(el => el.sequence === 0);

          const changeHistories = detailStoreInfo?.storeAddressHistory.filter(el => el.sequence > 0).sort((a, b) => a - b);

          return {
               showHistory: true,
               changeHistories,
               originAddress,
          };
     }, [detailStoreInfo]);

     const isStoreSelected = useMemo(() => {
          if (!activeStore || !tobaccoBookmarkedList) return false;

          const storeInfo = tobaccoBookmarkedList.find(el => el.managementNumber === activeStore.data.managementNumber);

          if (storeInfo) {
               return true;
          } else {
               return false;
          }
     }, [tobaccoBookmarkedList, activeStore]);

     const handleClickDirection = type => {
          const directionInfo = {
               coordinate: { x: activeStore?.x, y: activeStore?.y },
               name: activeStore?.name,
               isPlace: false,
          };

          if (type === "START") {
               setDirectionStart(directionInfo);
          }
          if (type === "END") {
               setDirectionEnd(directionInfo);
          }

          handleCloseDetailMode(false);
     };

     return (
          <TobaccoDetailWrap height={height} ref={infoEl}>
               <DetailModeTop>
                    <img onClick={handleCloseDetailMode} src="img/icons/svg/arrow-back.svg" alt="close-detail-mode" />
               </DetailModeTop>

               <div className="active-tobacco-store-info">
                    <div>
                         <div className="active-store-name">
                              <h2>{businessName}</h2>
                         </div>
                         <div className="active-tobacco-store-details">{contactNumber && ""}</div>
                         <p className="active-tobacco-tore-address">{address}</p>
                    </div>
                    <BtnsWrap>
                         <LeftBtnsWrap>
                              <RoundButton
                                   onClick={() => {
                                        handleClickFavoriteBtn(activeStore.data);
                                   }}
                                   active={isStoreSelected}
                              >
                                   {isStoreSelected ? <IconStarFill fillColor="#2971EE" /> : <IconStar lineColor="#F1F4F9" />}
                              </RoundButton>
                              {activeStore.data.managementNumber && (
                                   <RoundButton
                                        onClick={() => {
                                             handleCloseDetailMode();

                                             handleChangeMoveStoreInfo();
                                        }}
                                        active
                                   >
                                        <img src="img/icons/svg/u_edit.svg" alt="fix-position" width={20} />
                                   </RoundButton>
                              )}
                         </LeftBtnsWrap>

                         <DirectionBtnsWrap>
                              <DirectionButton text="출발" onClick={() => handleClickDirection("START")} />
                              <DirectionButton text="도착" onClick={() => handleClickDirection("END")} />
                         </DirectionBtnsWrap>
                    </BtnsWrap>
               </div>

               <TobaccoManagementInfoWrap>
                    <TobaccoManagementInfoTop>상세 내용</TobaccoManagementInfoTop>
                    <TobaccoManagementContentsWrap>
                         <TobaccoItemData border={false}>
                              <div style={{ paddingRight: 12 }}>
                                   <img src={"img/icons/store_round.png"} alt="store-round-icon" width={26} height={26} />
                              </div>
                              <div className="business-status">영업상태</div>
                              <div className="business-current">{detailedBusinessStatusName}</div>
                         </TobaccoItemData>
                         {businessStatusCode === "01" && (
                              <TobaccoItemData border>
                                   <div style={{ paddingRight: 12 }}>
                                        <img src={"img/icons/check_round.png"} alt="store-round-icon" width={26} height={26} />
                                   </div>
                                   <div className="business-status">영업 허가일</div>
                                   <div className="business-current">{approvalDate}</div>
                              </TobaccoItemData>
                         )}
                         {businessStatusCode === "02" && (
                              <>
                                   <TobaccoItemData border>
                                        <div style={{ paddingRight: 12 }}>
                                             <img src={"img/icons/suspended_round.png"} alt="store-round-icon" width={26} height={26} />
                                        </div>
                                        <div className="business-status">휴업 시작일</div>
                                        <div className="business-current">{suspensionStartDate}</div>
                                   </TobaccoItemData>

                                   <TobaccoItemData border>
                                        <div style={{ paddingRight: 12 }}>
                                             <img src={"img/icons/suspended_round.png"} alt="store-round-icon" width={26} height={26} />
                                        </div>
                                        <div className="business-status">휴업 종료일</div>
                                        <div className="business-current">{suspensionEndDate}</div>
                                   </TobaccoItemData>
                              </>
                         )}

                         {businessStatusCode === "03" && (
                              <TobaccoItemData border>
                                   <div style={{ paddingRight: 12 }}>
                                        <img src={"img/icons/closed_round.png"} alt="store-round-icon" width={26} height={26} />
                                   </div>
                                   <div className="business-status">폐업일</div>
                                   <div className="business-current">{closureDate}</div>
                              </TobaccoItemData>
                         )}
                    </TobaccoManagementContentsWrap>
               </TobaccoManagementInfoWrap>

               {addressChangeHistories.showHistory && (
                    <TobaccoManagementInfoWrap>
                         <TobaccoManagementInfoTop>주소 변경 내역</TobaccoManagementInfoTop>
                         <TobaccoManagementContentsWrap>
                              {addressChangeHistories.changeHistories.map((el, idx, total) => {
                                   const changedAddress = el.fullRoadAddress ?? el.fullLocationAddress;
                                   const changeAddressType = el.fullRoadAddress ? "[도로명]" : "[지번]";

                                   const beforeInfo = idx === total.length - 1 ? addressChangeHistories.originAddress : total[idx + 1];

                                   const beforeChangedAddress = beforeInfo.fullRoadAddress ?? beforeInfo.fullLocationAddress;
                                   const beforeChangeAddressType = beforeInfo.fullRoadAddress ? "[도로명]" : "[지번]";

                                   return (
                                        <TobaccoItemData key={`${el.sequence}-${el.fullRoadAddress}`} border={total.length > 1 && idx > 0}>
                                             <div className="history-change-wrap">
                                                  <div className="address">
                                                       (변경) {changeAddressType} {changedAddress}
                                                  </div>
                                                  <div className="address before">
                                                       (이전) {beforeChangeAddressType} {beforeChangedAddress}
                                                  </div>
                                                  <div className="update-date">업데이트 : {el.updateDate.replaceAll("-", ".")}</div>
                                                  <div className="update-date user">수행자 : {el.updateUserAccount}</div>
                                             </div>
                                        </TobaccoItemData>
                                   );
                              })}
                         </TobaccoManagementContentsWrap>
                    </TobaccoManagementInfoWrap>
               )}
          </TobaccoDetailWrap>
     );
};

export default TobaccoMobileDetail;

const LeftBtnsWrap = styled.div`
     display: flex;
     gap: 10px;
`;

const BtnsWrap = styled.div`
     display: flex;
     width: 100%;
     justify-content: space-between;
     padding-top: 10px;
`;

const DirectionBtnsWrap = styled.div`
     display: flex;
     gap: 6px;
`;
