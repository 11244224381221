import styled from "@emotion/styled";

export const ItemData = styled.div`
     display: flex;
     align-items: center;
     padding: 12px 12px 12px 0px;
     border-top: ${({ border }) => (border ? "0.4px solid #BDBDBD" : "none")};
     .title {
          margin-left: 12px;
          color: #1e2530;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          box-sizing: border-box;
          min-width: 68px;
     }

     .expectations {
          display: flex;
          align-items: center;
          gap: 10px;
     }

     .expectation-change-amount {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }

     .contents {
          color: #868782;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }
     .expectation-amount {
          color: #868782;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }
`;

export const ManagementInfoTop = styled.div`
     color: #3d4043;
     text-align: center;

     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     width: 100%;
     text-align: left;
     padding-bottom: 10px;
`;

export const ManagementInfoWrap = styled.div`
     padding: 10px 0px 26px;
`;

export const StoreDetailInfoWrap = styled.div`
     background-color: #ffffff;
     border-radius: 10px;
     padding: 12px 0px 0px;
     margin: 10px 0px;

     .title {
          padding-left: 16px;
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: 12px;
     }

     .line-wrap {
          box-sizing: border-box;
          padding-left: 16px;
          padding-bottom: 14px;
          width: 100%;
          .line {
               width: 100%;
               height: 1px;
               background: #bdbdbd;
          }
     }

     .info-item {
          padding: 0px 16px 16px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;

          .info-code-name {
               overflow: hidden;
               color: #1e2530;
               text-overflow: ellipsis;

               font-size: 12px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
          }

          .info-code-value {
               overflow: hidden;
               color: #1e2530;
               text-align: right;
               text-overflow: ellipsis;

               font-size: 12px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
          }
     }

     .info-code-value.yes-color {
          color: #216cf0;
     }

     .info-code-value.no-color {
          color: #f00;
     }

     .info-code-right {
          display: flex;
          align-items: center;
          width: 150px;
          justify-content: space-between;
     }

     .info-dode-pre-value {
          width: 80px;
          text-align: right;
          overflow: hidden;

          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
     }

     .info-dode-pre-value.up-color {
          color: #216cf0;
     }

     .info-dode-pre-value.down-color {
          color: #f00;
     }

     .info-dode-pre-value.diff-color {
          color: #1e2530;
     }
`;

export const ManagementContentsWrap = styled.div`
     box-sizing: border-box;
     background-color: #ffffff;
     width: 100%;
     border-radius: 10px;
     padding-left: 16px;
`;

export const DetailModeTop = styled.div`
     padding-bottom: 14px;
     padding-left: 18px;

     img {
          cursor: pointer;
     }
`;
export const StoreDetailWrap = styled.div`
     div {
          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }
     }

     box-sizing: border-box;
     z-index: 900;
     background-color: #f1f4f9;
     height: 100%;
     display: flex;
     flex-direction: column;

     padding: 18px 0px;
     position: fixed;
     top: 30px;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "490px" : "130px")};
     width: 360px;
     height: calc(100vh - 60px);
     border-radius: 10px;
     box-shadow:
          0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset,
          0px 2px 4px 0px rgba(0, 0, 0, 0.1);

     .active-store-info {
          padding: 0px 18px;
     }

     h2 {
          word-break: keep-all;
          color: #1e2530;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 100% */
          padding-bottom: 12px;
     }

     .active-store-details {
          color: #71757a;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
     }

     .active-store-address {
          color: #1e2530;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
     }

     .info-default-manager {
          display: flex;
          align-items: top;
          justify-content: space-between;
          margin: 20px -4.5px 20px;

          > li {
               flex: 1;
               border: 1px solid #d1d5db;
               box-sizing: border-box;
               border-radius: 8px;
               margin: 0 4.5px;
               text-align: center;
               padding: 4px 4px 6px;
               overflow: hidden;
          }
          > li span {
               font-weight: 400;
               font-size: 14px;
               line-height: 18px;
          }
          > li p {
               font-weight: 700;
               font-size: 14px;
               line-height: 18px;
          }
     }

     .info-detail-wrap {
          overflow-y: auto;
          height: 100%;
     }

     .info-detail {
          height: 100%;
          max-height: 500px;
          box-sizing: border-box;
          padding: 9px 18px 0px;
     }
     .info-detail-header {
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid #868782;

          box-sizing: border-box;

          &.on {
               position: fixed;
               top: 53px;
               width: 360px;
               margin-left: -20px;
               z-index: 50;
               border-top-width: 0;
          }

          > li {
               flex: 1;
               text-align: center;
          }
          > li button {
               color: #1e2530;
               text-align: center;

               font-family:
                    "Pretendard Variable",
                    Pretendard,
                    -apple-system,
                    BlinkMacSystemFont,
                    system-ui,
                    Roboto,
                    "Helvetica Neue",
                    "Segoe UI",
                    "Apple SD Gothic Neo",
                    "Noto Sans KR",
                    "Malgun Gothic",
                    "Apple Color Emoji",
                    "Segoe UI Emoji",
                    "Segoe UI Symbol",
                    sans-serif !important;
               font-style: normal;
               font-weight: 500;
               line-height: 20px; /* 142.857% */
               letter-spacing: -0.23px;
               color: #868782;

               background-color: transparent;
               box-sizing: border-box;
               font-size: 14px;
               padding: 14px 0;
               width: 100%;
               &.active {
                    font-size: 14px !important;
                    color: #1e2530;
                    font-weight: 700;
                    border-bottom: 2px solid #1e2530;
                    padding: 14px 0 11px;
                    font-weight: 700;
               }
          }
     }

     .info-detail-keyword {
          padding-bottom: 22px;
          border-bottom: 1px solid #e5e7eb;
     }

     .info-detail-keyword-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
               display: flex;
               gap: 5px;
               color: #3d4043;

               font-size: 14px;
               font-style: normal;
               font-weight: 600;
               line-height: normal;
          }
          .accent {
               color: #216cf0;
          }

          .review-date-wrap {
               display: flex;
               gap: 2px;
               padding-bottom: 9px;

               div {
                    cursor: pointer;
                    width: 46px;
                    height: 21px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    color: rgba(186, 190, 196, 0.5);
                    line-height: normal;
                    background: #fff;
               }

               .active {
                    background: #babec4;
                    color: #f1f4f9;
               }
          }
     }

     .info-detail-review {
          padding-bottom: 30px;
     }
     .info-detail-review > ul {
          background-color: #fff;
          border-radius: 10px;
     }

     .info-detail-review > ul li {
          padding: 6px 0;
          border-bottom: 0.4px solid #bdbdbd;
     }

     /* 마지막 li 항목에서 border-bottom 제거 */
     .info-detail-review > ul li:last-child {
          border-bottom: none;
     }
     .info-detail-review .reviewer {
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
     }
     .info-detail-review .body {
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }

     .info-detail-review .rest {
          overflow: hidden;
          color: #868782;
          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
     }

     .info-detail-review-none {
          padding: 50px 0 !important;
          .info-detail-review-none-icon {
               display: inline-block;
               border-radius: 100%;
               border: 2px solid #666;
               margin-bottom: 20px;
               > svg {
                    font-size: 50px;
                    color: #666;
               }
          }
          text-align: center;
     }

     .review-contents {
          padding: 6px 16px;
     }
`;

export const InfoKeywordBarWrap = styled.li`
     position: relative;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 10px 15px;
     box-sizing: border-box;
     border-radius: 8px;
     margin-bottom: 5px;
     overflow: hidden;
     color: #1e2530;
     text-overflow: ellipsis;

     font-size: 10px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     background: #fff;

     &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: ${({ per }) => (per ? `${per}%;` : "0;")};
          height: 41px;
          background-color: rgba(33, 108, 240, 0.36);
          z-index: 10px;
     }

     &:last-child {
          margin-bottom: 0;
     }
     > p {
          font-weight: 500;
          font-size: 14px;
          z-index: 30;
     }
     > span {
          overflow: hidden;
          color: #216cf0;
          text-align: right;
          text-overflow: ellipsis;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          z-index: 30;
     }
`;

export const InfobarWrap = styled.div`
     position: fixed;
     top: 30px;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "500px" : "120px")};
     width: 360px;
     height: calc(100vh - 60px);
     max-height: 900px;
     background: #f1f4f9;
     display: flex;
     flex-direction: column;
     width: 360px;
     box-sizing: border-box;
     padding: 26px 20px 0;
     border: 1px solid #e5e7eb;
     border-radius: 16px;

     .info-detail {
          flex: 1;
          overflow-y: auto;
          margin-right: -20px;

          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }
     }

     .info-detail-header {
          display: flex;
          align-items: center;
          border-top: 1px solid #d1d5db;
          border-bottom: 1px solid #d1d5db;
          box-sizing: border-box;
          background: #fff;
          margin-bottom: 28px;
          &.on {
               position: fixed;
               top: 53px;
               width: 360px;
               margin-left: -20px;
               z-index: 50;
               border-top-width: 0;
          }

          > li {
               flex: 1;
               text-align: center;
          }

          > li .info-li-left {
               flex: 1;
               text-align: left;
          }

          > li button {
               box-sizing: border-box;
               font-size: 16px;
               padding: 14px 0;
               &.active {
                    border-bottom: 3px solid #1473e6;
                    padding: 14px 0 11px;
                    font-weight: 700;
               }
          }
     }

     /* .info-detail-review {
		padding-top: 12px;
	} */

     .info-detail-review > ul li {
          border-bottom: 1px solid #e5e7eb;
          padding: 12px 0;
     }

     .info-detail-review .reviewer {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
     }
     .info-detail-review .body {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
     }

     .info-detail-review .rest {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          color: #727272;
     }

     .info-detail-review-none {
          padding: 50px 0 !important;
          .info-detail-review-none-icon {
               display: inline-block;
               border-radius: 100%;
               border: 2px solid #666;
               margin-bottom: 20px;
               > svg {
                    font-size: 50px;
                    color: #666;
               }
          }
          text-align: center;
     }

     .info-detail-keyword {
          padding-bottom: 22px;
          border-bottom: 1px solid #e5e7eb;
     }

     .info-detail-keyword-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > h2 {
               font-weight: bold;
               font-size: 18px;
               line-height: 45px;
               vertical-align: middle;
          }
          > h2 em {
               font-weight: 500;
               font-size: 18px;
               line-height: 45px;
               color: #1473e6;
               vertical-align: bottom;
          }

          .select-wrap {
               border-width: 0;
               padding: 0;
               > p {
                    margin: 0 4px;
               }
               > ul {
                    top: calc(100% - 4px);
               }
          }
     }

     .info-default {
          margin-bottom: 28px;
     }

     .info-default h1 {
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          vertical-align: top;
          margin-bottom: 24px;
          background: #fff;
          box-sizing: border-box;
          position: relative;
          padding-right: 26px;
          &.on {
               position: fixed;
               top: 0;
               padding: 14px 20px;
               width: 360px;
               margin-left: -20px;
               z-index: 50;
               border-bottom: 1px solid #d1d5db;
               margin-bottom: 0;
          }
     }
     .info-default h1 em {
          position: absolute;
          top: 0;
          right: 0;
          color: #1473e6;
          font-weight: 400;
          font-size: 25px;
          vertical-align: top;
          svg {
               margin-top: -5px;
               margin-left: 6px;
          }
     }

     .info-default p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
     }

     .info-default .info-default-manager {
          display: flex;
          align-items: top;
          justify-content: space-between;
          margin: 20px -4.5px 0;

          > li {
               flex: 1;
               border: 1px solid #d1d5db;
               box-sizing: border-box;
               border-radius: 8px;
               margin: 0 4.5px;
               text-align: center;
               padding: 4px 4px 6px;
               overflow: hidden;
          }
          > li span {
               font-weight: 400;
               font-size: 14px;
               line-height: 18px;
          }
          > li p {
               font-weight: 700;
               font-size: 14px;
               line-height: 18px;
               /* overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap; */
          }
     }

     .info-default-header {
          text-align: right;
          margin-bottom: 20px;

          button {
               margin-left: 25px;
          }

          svg.ri {
               width: 26px;
               height: 26px;
          }
     }

     svg {
          vertical-align: middle;
     }
`;

export const VotedkeywordsWrap = styled.ul`
     li {
          padding: 5px 20px;
          background: #ffffff;
          border: 0.5px solid #e2e5e9;
          box-sizing: border-box;
          box-shadow: 0px 2px 5px rgba(31, 41, 55, 0.15);
          border-radius: 8px;
          color: #374151;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
     }
`;
