import styled from "@emotion/styled";

interface ITobaccoMobileHomeWrap {
     height: number | null;
     isTabletOrMobile: boolean;
}

export const TobaccoMobileHomeWrap = styled.div<ITobaccoMobileHomeWrap>`
     position: fixed;
     display: flex;
     height: ${({ height }) => (height ? `${height}px !important;` : "100%;")};
     right: 0;
     top: 0;
     left: 0;
     flex-direction: ${({ isTabletOrMobile }) => (!isTabletOrMobile ? "row;" : "column;")};
     /* height: 100%; */

     overflow-y: auto;

     .close-wrap {
          position: absolute;
          z-index: 15;
          top: 30px;
          left: 100%;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
          border-radius: 0 5px 5px 0;
          overflow: hidden;
          button {
               background-color: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
               border: 1px solid #e0e0e0;
               border-left-width: 0;
          }
          svg {
               width: 24px;
               height: 24px;
          }
     }

     .left-container {
          box-sizing: border-box;
          position: relative;
          display: flex;
          z-index: 10;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
     }

     .store-detail-default {
          /* margin-bottom: 30px; */
          padding: 30px 20px;
          border-bottom: 6px solid #f0f2f5;
     }
     .store-detail-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
     }

     .store-detail-title h1 {
          font-size: 24px;
     }
     .store-detail-title button svg {
          width: 22px;
          height: 22px;

          path {
               color: #d9d9d9;
          }
     }

     .store-detail-title button.on svg path {
          color: #ffeb5a;
     }

     .store-admin {
          /* padding: 30px 0; */
          border-bottom: 6px solid #f0f2f5;
          > div {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 16px;
               border-bottom: 1px solid #e0e0e0;
          }
     }

     .custom-infow-window {
          background-color: #fff;
          color: #333;
          padding: 5px;
          border: #e0e0e0;
          border-radius: 5px;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
     }

     .label-textarea {
          display: block;
          font-size: 14px;
          color: #757575;
          margin-bottom: 8px;
     }

     #map {
          flex: 1;
     }

     #map .marker-wrap {
          position: relative;
          padding-top: 8px;
          &.active .marker-button,
          &.hover .marker-button {
               padding: 4px 8px 4px 4px;
               z-index: 20;
          }
          &.active .marker-button > .marker-none-img,
          &.hover .marker-button > .marker-none-img {
               margin-right: 6px;
               margin-left: 2px;
          }
     }

     #map .marker-button {
          position: relative;
          display: flex;
          padding: 4px;
          border-radius: 23px;
          background: #fff;
          align-items: center;
          white-space: nowrap;

          & > .marker-none-img {
               width: 30px;
               height: 30px;
               background-color: #ffffff;
               border-radius: 100%;

               display: flex;
               align-items: center;
               justify-content: center;
          }
          & > svg {
               width: 30px;
          }
     }

     #map .marker-button.open {
          border: 1px solid #3e85e8;

          .marker-button-count {
               border: 1px solid #3e85e8;
               color: #3d4043;
          }

          .marker-button-new {
               padding: 0px 2px;
               border: 1.5px solid #3e85e8;
               color: #3d4043;
               font-size: 8px;
               font-style: normal;
               font-weight: 600;
          }
     }

     #map .marker-button.open.rfc {
          border: 2px solid rgba(242, 101, 34, 0.7);
          & .marker-none-img {
               background-color: rgba(242, 101, 34, 0.7);
          }
          .marker-button-count {
               border: 1px solid rgba(242, 101, 34, 1);
               color: rgba(242, 101, 34, 1);
          }
     }

     #map .marker-button.suspended {
          border: 1px solid #666666;
          &:after {
               box-shadow: -1px 1px 1px 0 #666666;
          }
          & .marker-none-img {
               background-color: #ffffff;
          }
          .marker-button-count {
               border: 1px solid #666666;
               color: #3d4043;
          }
          .marker-button-new {
               padding: 0px 2px;
               border: 1.5px solid #666666;
               color: #3d4043;
               font-size: 8px;
               font-style: normal;
               font-weight: 600;
          }
     }

     #map .marker-button.place {
          border: 1px solid #57cc98;

          &:after {
               box-shadow: -1px 1px 1px 0 #57cc98;
          }

          & .marker-none-img {
               background-color: #57cc98;
          }

          .marker-button-count {
               border: 1px solid #57cc98;
               color: #3d4043;
          }

          & svg {
               path: {
                    fill: white;
               }
          }
     }

     #map .marker-button.closed {
          border: 1px solid #ff0000;
          &:after {
               box-shadow: -1px 1px 1px 0 #ff0000;
          }
          & .marker-none-img {
               background-color: #ffffff;
          }
          .marker-button-count {
               border: 1px solid #ff0000;
               color: #3d4043;
          }

          .marker-button-new {
               padding: 0px 2px;
               border: 1.5px solid #ff0000;
               color: #3d4043;
               font-size: 8px;
               font-style: normal;
               font-weight: 600;
          }
     }

     #map .marker-button:after {
          background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
          box-shadow: -1px 1px 1px 0 #0475f4;
          content: "";
          height: 5px;
          left: 19px;
          position: absolute;
          top: 37px;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 5px;
     }

     #map .marker-button.store.rfc:after {
          background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
          box-shadow: -2px 2px 1px rgba(242, 101, 34, 0.7);
          content: "";
          height: 9px;
          left: 19px;
          position: absolute;
          top: 37px;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 9px;
     }

     #map .marker-wrap.active img,
     #map .marker-wrap.hover img {
          margin-right: 6px;
     }
     #map .marker-button .marker-button-name {
          /* width: 0;
		overflow: hidden; */
          display: none;
          color: #333;
          font-size: 12px;
          font-weight: 500;
          opacity: 0;
          font-family:
               "Pretendard Variable",
               Pretendard,
               -apple-system,
               BlinkMacSystemFont,
               system-ui,
               Roboto,
               "Helvetica Neue",
               "Segoe UI",
               "Apple SD Gothic Neo",
               "Noto Sans KR",
               "Malgun Gothic",
               "Apple Color Emoji",
               "Segoe UI Emoji",
               "Segoe UI Symbol",
               sans-serif !important;
     }
     #map .marker-wrap.active .marker-button .marker-button-name,
     #map .marker-wrap.hover .marker-button .marker-button-name {
          /* transition: all 400ms; */
          /* width: 100%; */
          display: inline-block;
          opacity: 1;
     }

     #map .marker-button-count {
          width: 20px;
          height: 15px;
          position: absolute;
          top: -6px;
          right: -4px;
          border-radius: 9px;
          background-color: #fff;
          vertical-align: middle;
          text-align: center;
          font-size: 11px;
          line-height: 15px;
          font-weight: bold;
     }
     #map .marker-button-new {
          width: 20px;
          height: 13px;
          position: absolute;
          top: -3px;
          right: -10px;
          border-radius: 9px;
          background-color: #fff;
          vertical-align: middle;
          text-align: center;
          font-size: 11px;
          line-height: 15px;
          font-weight: bold;
     }

     #map .marker-wrap.active .marker-button-child {
          display: block;
     }

     #map .marker-button-child {
          display: none;
          position: absolute;
          bottom: 100%;
          left: 0;
          margin-bottom: 8px;
          z-index: 1000;
          > ul {
               max-height: 270px;
               overflow-y: auto;
               background-color: #fff;
               border: 1px solid rgba(0, 0, 0, 0.05);
               box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
               &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                    background: #ffffff;
               }
               &::-webkit-scrollbar-thumb {
                    border-radius: 3.5px;
                    background-color: #ced4da;

                    &:hover {
                         background-color: #adb5bd;
                    }
               }
               &::-webkit-scrollbar-track {
                    background: #ffffff;
               }

               > li {
                    padding: 10px 28px;
                    white-space: nowrap;
                    border-bottom: 1px solid #ecf0f2;
                    text-align: left;
                    color: #333;

                    &:last-child {
                         border-width: 0;
                    }
               }
          }
     }

     .active-store-info {
          box-sizing: border-box;
          position: absolute;
          bottom: 80px;
          left: 0;
          width: 100%;
          padding: 0 20px 25px;
          z-index: 500;
          > div {
               box-sizing: border-box;
               width: 100%;
               padding: 16px 14px 18px;
               box-shadow: rgb(0 0 0 / 15%) 3px 3px 15px;
               border-radius: 10px;
               background-color: #fff;

               .active-store-name {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 8px;
               }

               .active-store-name h2 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                    flex: 1;
                    margin-right: 14px;
               }

               .active-store-details {
                    display: flex;
                    align-items: center;
               }

               .active-store-details p {
                    color: #777;

                    &:nth-of-type(2)::before {
                         display: inline-block;
                         width: 1px;
                         height: 8px;
                         background-color: rgb(226, 226, 226);
                         margin: -1px 6px 1px;
                         vertical-align: -1px;
                         content: "";
                    }
               }

               p.active-store-address {
                    color: #333;
               }
          }
     }

     .active-store-info-close {
          position: absolute;
          top: 11.5px;
          right: 35px;
          padding: 6px;

          > svg {
               width: 14px;
               height: 14px;
          }
     }

     .marker-dot {
          box-sizing: border-box;
          position: absolute;
          bottom: -10px;
          left: 16.5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
     }

     .marker-dot.place {
          border: 1px solid #56cb98;
     }
     .marker-dot.open {
          border: 1px solid #3e85e8;
     }
     .marker-dot.closed {
          border: 1px solid #ff0000;
     }
     .marker-dot.suspended {
          border: 1px solid #666666;
     }
`;

export const MobileFilterOpenBtn = styled.div`
     cursor: pointer;
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 2;
     top: 72px;
     left: 10px;
     width: 26px;
     height: 26px;
     background: #ffffff;
     border-radius: 8px;
     border: 1px solid #dadce0;
     box-shadow:
          0px 1px 2px 0px rgba(0, 0, 0, 0.05),
          0px 1px 10px 0px rgba(0, 0, 0, 0.15);
`;

export const MobileDirectionOpenBtn = styled.div`
     cursor: pointer;
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 2;
     top: 70px;
     right: 50px;
     width: 40px;
     height: 40px;
     border-radius: 8px;
     background: #ffffff;
     box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
`;
