import { useState, useMemo, useCallback, useEffect } from "react";
import {
     DirectionSearchWrap,
     DirectionResultWrap,
     MobileTobaccoDirectionWrap,
     MobileTobaccoDirectionBtnWrap,
     TobaccoSearchResultWrap,
     ItemTobaccoSearchResult,
} from "./MobileTobaccoDirection.styled.ts";
import { Input } from "@mui/material";
import debounce from "lodash/debounce";
import { postTobaccoStoreSearchApi } from "../../api/store";
import IconDirectionArrow from "../MobileFilterV2/svgComponents/IconDirectionArrow.jsx";
import IconMap from "../MobileFilterV2/svgComponents/IconMap.jsx";
import { INITIAL_LAT, INITIAL_LNG } from "../../constants/map.ts";

const { naver } = window;

const MobileTobaccoDirection = ({
     map,
     directionDrawerClose,
     directionStart,
     setDirectionStart,
     directionEnd,
     setDirectionEnd,
     account,
     filterOption,
     handleClickDirectionWithPoint,
     directionDrawerSearchMode,
     setDirectionDrawerSearchMode,
     onClickCloseDirectionWithPoint,
     showDirectionPointMessage,
}) => {
     const [searchValue, setSearchValue] = useState("");
     const [searchResult, setSearchResult] = useState(null);

     const handleClickDirection = () => {
          if (!directionStart.coordinate || !directionEnd.coordinate) {
               window.alert("출발지와 도착지를 모두 입력해 주세요");
               return;
          }

          if (
               directionStart.coordinate.x === directionEnd.coordinate.x &&
               directionStart.coordinate.y === directionEnd.coordinate.y &&
               directionStart.name === directionEnd.name
          ) {
               window.alert("출발지와 도착지는 동일할 수 없습니다");
               return;
          }

          if (directionStart.coordinate && directionEnd.coordinate) {
               const url = `http://map.naver.com/index.nhn?slng=${directionStart.coordinate.x}&slat=${directionStart.coordinate.y}&stext=${directionStart.name ?? "출발지"}&elng=${directionEnd.coordinate.x}&elat=${directionEnd.coordinate.y}&etext=${directionEnd.name ?? "도착지"}&menu=route&pathType=3`;
               window.open(url, "_blank");
          }
     };

     const fetchSearchResults = async query => {
          if (!query.trim()) {
               setSearchResult(null); // 빈 검색어일 경우 결과 초기화
               return;
          }

          try {
               const result = await postTobaccoStoreSearchApi({
                    storeName: query,
                    account,
                    includeClosedWithin: filterOption?.includeClosedWithin,
                    includeSuspendedStore: "Y",
               });
               setSearchResult(result.data.data); // 검색 결과 업데이트
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               setSearchResult(null); // 오류 발생 시 결과 초기화
          }
     };

     // debounce를 사용하여 검색 호출 제어
     const debouncedSearch = useMemo(() => debounce(fetchSearchResults, 300), [filterOption, account]);

     // 검색어 변경 핸들러
     const handleSearchChange = useCallback(
          e => {
               const query = e.target.value;
               setSearchValue(query);
               debouncedSearch(query);
          },
          [debouncedSearch],
     );

     const handleSelectSearchResult = store => {
          if (directionDrawerSearchMode.type === "START")
               setDirectionStart({ coordinate: { x: store.xlongitude, y: store.ylatitude }, name: store.businessName, isPlace: false });
          if (directionDrawerSearchMode.type === "END")
               setDirectionEnd({ coordinate: { x: store.xlongitude, y: store.ylatitude }, name: store.businessName, isPlace: false });

          setSearchValue("");
          setSearchResult(null);
          setDirectionDrawerSearchMode({ show: false, type: null });
     };

     const handleChangeDirection = () => {
          const currentStart = { ...directionStart };
          const currentEnd = { ...directionEnd };

          setDirectionStart(currentEnd);
          setDirectionEnd(currentStart);
     };

     const handleClickSetMyPlace = () => {
          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
                         map.current.setCenter(golocationPosition);
                         if (directionDrawerSearchMode.type === "START") {
                              setDirectionStart({ coordinate: { x: golocationPosition.x, y: golocationPosition.y }, name: "내 위치", isPlace: true });
                         }
                         if (directionDrawerSearchMode.type === "END") {
                              setDirectionEnd({ coordinate: { x: golocationPosition.x, y: golocationPosition.y }, name: "내 위치", isPlace: true });
                         }
                         setDirectionDrawerSearchMode({ show: false, type: null });
                    },
                    error => {
                         if (directionDrawerSearchMode.type === "START") {
                              setDirectionStart({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                              return;
                         }
                         if (directionDrawerSearchMode.type === "END") {
                              setDirectionEnd({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                              return;
                         }
                         setDirectionDrawerSearchMode({ show: false, type: null });
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               if (directionDrawerSearchMode.type === "START") {
                    setDirectionStart({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                    return;
               }
               if (directionDrawerSearchMode.type === "END") {
                    setDirectionEnd({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                    return;
               }
               setDirectionDrawerSearchMode({ show: false, type: null });
          }
     };

     // 컴포넌트 언마운트 시 debounce 해제
     useEffect(() => {
          return () => {
               debouncedSearch.cancel();
          };
     }, [debouncedSearch]);

     return (
          <>
               <MobileTobaccoDirectionWrap>
                    <div className="top">
                         {!directionDrawerSearchMode.show && <div>도보거리 계산하기</div>}
                         {directionDrawerSearchMode.show && (
                              <div
                                   onClick={() => {
                                        setDirectionDrawerSearchMode({ show: false, type: null });
                                        onClickCloseDirectionWithPoint();
                                   }}
                              >
                                   <img src="img/icons/svg/arrow-back.svg" width={22} alt="back" />
                              </div>
                         )}
                         <div
                              onClick={() => {
                                   onClickCloseDirectionWithPoint();
                                   directionDrawerClose();
                              }}
                         >
                              <img src="img/icons/svg/u_x.svg" width={22} alt="close" />
                         </div>
                    </div>

                    {!directionDrawerSearchMode.show && (
                         <>
                              <DirectionResultWrap>
                                   <div className="results">
                                        <div className="start" onClick={() => setDirectionDrawerSearchMode({ show: true, type: "START" })}>
                                             <div className="title">출발</div>
                                             <div className="contents">{directionStart.name ?? "지역, 점포명으로 검색해 주세요"}</div>
                                        </div>
                                        <div className="end" onClick={() => setDirectionDrawerSearchMode({ show: true, type: "END" })}>
                                             <div className="title">도착</div>
                                             <div className="contents">{directionEnd.name ?? "지역, 점포명으로 검색해 주세요"}</div>
                                        </div>
                                   </div>

                                   <div className="change-btn" onClick={handleChangeDirection}>
                                        <img src="img/icons/svg/u_exchange.svg" alt="exchange" width={22} />
                                   </div>
                              </DirectionResultWrap>
                              <MobileTobaccoDirectionBtnWrap>
                                   <div onClick={handleClickDirection}>길찾기</div>
                              </MobileTobaccoDirectionBtnWrap>
                         </>
                    )}

                    {directionDrawerSearchMode.show && (
                         <DirectionSearchWrap showDirectionPointMessage={showDirectionPointMessage}>
                              <div className="search-wrap">
                                   <div className="input-wrap">
                                        <div className="title">{directionDrawerSearchMode.type === "START" ? "출발" : "도착"}</div>
                                        <Input
                                             sx={{
                                                  width: "100%",
                                             }}
                                             disableUnderline
                                             placeholder={
                                                  directionDrawerSearchMode.type === "START"
                                                       ? (directionStart?.name ?? "지역, 점포명으로 검색해 주세요")
                                                       : directionDrawerSearchMode.type === "END"
                                                         ? (directionEnd?.name ?? "지역, 점포명으로 검색해 주세요")
                                                         : ""
                                             }
                                             value={searchValue}
                                             onChange={handleSearchChange}
                                        />
                                   </div>

                                   <div className="search">
                                        <img src="img/icons/svg/u_search.svg" alt="search" width={22} />
                                   </div>
                              </div>
                              <div className="direction-btns">
                                   <div onClick={handleClickSetMyPlace}>
                                        <IconDirectionArrow /> 내 위치로 지정
                                   </div>
                                   <div
                                        onClick={() => {
                                             handleClickDirectionWithPoint(directionDrawerSearchMode.type);
                                        }}
                                   >
                                        <IconMap /> 지도에서 지정
                                   </div>
                              </div>

                              {!showDirectionPointMessage && searchResult && (
                                   <TobaccoSearchResultWrap>
                                        {searchResult.map(item => {
                                             const address = item.fullRoadAddress ?? item.fullLocationAddress;

                                             return (
                                                  <ItemTobaccoSearchResult onClick={() => handleSelectSearchResult(item)} key={item.managementNumber}>
                                                       <div className="title">{item.businessName}</div>
                                                       {item.contactNumber && <div className="contact-number">{item.contactNumber}</div>}
                                                       <div className="address">{address}</div>
                                                  </ItemTobaccoSearchResult>
                                             );
                                        })}
                                   </TobaccoSearchResultWrap>
                              )}
                         </DirectionSearchWrap>
                    )}
               </MobileTobaccoDirectionWrap>
          </>
     );
};

export default MobileTobaccoDirection;
