import { useRef } from "react";
import styled from "styled-components";
import { useClickAway } from "react-use";
// svg
import { ReactComponent as Close } from "../../assets/close.svg";

const Modal = ({
     children,
     status = "default",
     headline,
     open,
     onClose,
     alignItems,
     className,
     width,
     height,
     noHeader = false,
     headUnderline = true,
     headCloseButton = true,
     isTransparent = false,
     ...rest
}) => {
     const ref = useRef(null);
     useClickAway(ref, e => {
          if (onClose) {
               console.log("e>>>", e);
               e.preventDefault();
               onClose();
          }
     });

     if (!open) {
          return <></>;
     }
     return (
          <ModalBox
               className={className}
               status={status}
               headline={headline}
               alignItems={alignItems}
               width={width}
               height={height}
               headUnderline={headUnderline}
               isTransparent={isTransparent}
               {...rest}
          >
               <div className="modal" ref={ref}>
                    {!noHeader && (
                         <div className="modal-header">
                              {headline && <h4>{headline}</h4>}
                              {headCloseButton && (
                                   <button
                                        className="btn-close"
                                        type="button"
                                        onClick={e => {
                                             e.stopPropagation();
                                             onClose();
                                        }}
                                   >
                                        <Close />
                                   </button>
                              )}
                         </div>
                    )}
                    {children}
               </div>
          </ModalBox>
     );
};
export default Modal;

const ModalBox = styled.div`
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     position: fixed;
     top: 0;
     left: 0;
     z-index: 500;
     display: flex;
     justify-content: center;
     ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : "align-items:center;")}

     > .modal {
          ${({ width }) => {
               return width ? `width:${width};` : "width: 520px;";
          }}
          ${({ height }) => {
               return height ? `height:${height};` : "height: unset;";
          }}
		border-radius: 8px;
          box-shadow: ${({ isTransparent }) => (isTransparent ? "none" : "0 2px 8px 0 rgba(0, 0, 0, 0.16)")};
          background-color: ${({ isTransparent }) => (isTransparent ? "transparent" : "#ffffff")};
     }

     .modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 24px 14px;
          ${({ headUnderline }) => headUnderline && `border-bottom: 1px solid #e0e0e0;`}
     }
     .modal-header h4 {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #333333;

          > svg {
               width: 20px;
               height: 20px;
          }

          /* ${({ status }) =>
               status === "error" &&
               `color: #ee4238;
        & > svg path{
          stroke:#ee4238;
        }
        `}

		${({ status }) =>
               status === "success" &&
               `color: #2c9985;
         & > svg path{
          stroke:#2c9985;
        }
        `} */
     }

     .modal-header h4 > svg {
          margin-right: 8px;
     }

     .modal-header > button {
          margin-left: auto;
          opacity: 0.6;
     }
     .modal-header > button:hover {
          opacity: 1;
     }
     .modal-body {
          padding: 20px 24px 40px;
     }
     .modal-body > p {
          color: #757575;
     }
     .modal-footer {
          display: flex;
          align-items: center;
          padding: 0 24px 24px;
     }
     .modal-footer-left {
          margin-right: auto;
     }

     .modal-footer-right {
          margin-left: auto;
     }
     .modal-footer-right > button {
          margin-left: 8px;
     }
     .modal-footer-center {
          margin: 0 auto;
     }

     /* only text */
     ${({ headline }) =>
          !headline &&
          `.modal {
            .modal-header {
                height: 60px;
                border-bottom: none;
            }
            .modal-body {
                padding-top: 0;
                padding-bottom: 60px;
            }
            .modal-body > p {
                color: #333333;
        }}
    `}

     .btn-close > svg {
          width: 18px;
          height: 18px;
     }
`;
