import { LONGPRESS_DURATION } from "../constants/time";

const { naver } = window;

export const handleMapClick = (rightClickInfoWindowRef, map, directionChangeFlagRef, changePlaceRef, setModalOpen) => {
     return naver.maps.Event.addListener(map.current, "click", e => {
          if (directionChangeFlagRef.current) {
               changePlaceRef.current = e.coord;

               setModalOpen(true);
          }
          rightClickInfoWindowRef.current?.close();
          rightClickInfoWindowRef.current = null;
     });
};

export const handleStoreMapClick = (map, rightClickInfoWindowRef) => {
     return naver.maps.Event.addListener(map.current, "click", e => {
          rightClickInfoWindowRef.current?.close();
          rightClickInfoWindowRef.current = null;
     });
};

export const handleRightClick = (map, setFindDirectionAddress, rightClickInfoWindowRef) => {
     return naver.maps.Event.addListener(map.current, "rightclick", e => {
          const infoWindow = new naver.maps.InfoWindow({
               anchorSkew: false,
               maxWidth: 100,
               borderColor: "transparent",
               backgroundColor: "transparent",
               anchorSize: { width: 0, height: 0 },
          });

          const container = document.createElement("div");
          container.style.cssText = "position: relative; padding: 10px; min-width:1px; background-color: transparent;";

          const buttonContainer = document.createElement("div");
          buttonContainer.style.cssText =
               "border-radius:8px; position: absolute;display:flex;justify-content:space-between;align-items:center; width:150px; top: 10px; left: 10px; background-color: white; box-shadow: 0 2px 4px rgba(0,0,0,0.2);";

          const startDiv = document.createElement("div");
          startDiv.textContent = "출발";
          startDiv.style.cssText =
               "border-right:1px solid #e6e6e6; cursor: pointer; width:100%; display:flex; justify-content:center; align-items:center; height:30px; padding: 5px; border-bottom: 1px solid #ddd;";

          const endDiv = document.createElement("div");
          endDiv.textContent = "도착";
          endDiv.style.cssText =
               "cursor: pointer; width:100%; display:flex; justify-content:center; align-items:center; height:30px; padding: 5px; border-bottom: 1px solid #ddd;";

          startDiv.addEventListener("click", () => {
               infoWindow.close();

               setFindDirectionAddress({
                    type: "START",
                    coordinate: {
                         x: e.coord.x,
                         y: e.coord.y,
                    },
               });
          });

          endDiv.addEventListener("click", () => {
               infoWindow.close();

               setFindDirectionAddress({
                    type: "END",
                    coordinate: {
                         x: e.coord.x,
                         y: e.coord.y,
                    },
               });
          });

          buttonContainer.appendChild(startDiv);
          buttonContainer.appendChild(endDiv);
          container.appendChild(buttonContainer);

          infoWindow.setContent(container);
          infoWindow.open(map.current, e.coord);

          rightClickInfoWindowRef.current = infoWindow;
     });
};

export const handleTobaccoRightClick = (rightClickInfoWindowRef, map, setFindDirectionAddress, setSelectedPosition) => {
     return naver.maps.Event.addListener(map.current, "rightclick", e => {
          const infoWindow = new naver.maps.InfoWindow({
               anchorSkew: false,
               maxWidth: 100,
               borderColor: "transparent",
               backgroundColor: "transparent",
               anchorSize: { width: 0, height: 0 },
          });

          const container = document.createElement("div");
          container.style.cssText = "position: relative; padding: 10px; min-width:1px; background-color: transparent;";

          const totalContainer = document.createElement("div");
          totalContainer.style.cssText =
               "border-radius:8px; overflow:hidden; position: absolute;display:flex;flex-direction:column; justify-content:space-between;align-items:center; width:150px; top: 10px; left: 10px; background-color: white; box-shadow: 0 2px 4px rgba(0,0,0,0.2);";

          const buttonContainer = document.createElement("div");
          buttonContainer.style.cssText =
               "display:flex;justify-content:space-between;align-items:center; width:150px; top: 10px; left: 10px; background-color: white;";

          const startDiv = document.createElement("div");
          startDiv.textContent = "출발";
          startDiv.style.cssText =
               "border-right:1px solid #e6e6e6; cursor: pointer; width:100%; display:flex; justify-content:center; align-items:center; height:30px; padding: 5px; border-bottom: 1px solid #ddd;";

          const endDiv = document.createElement("div");
          endDiv.textContent = "도착";
          endDiv.style.cssText =
               "cursor: pointer; width:100%; display:flex; justify-content:center; align-items:center; height:30px; padding: 5px; border-bottom: 1px solid #ddd;";

          startDiv.addEventListener("click", () => {
               infoWindow.close();

               setFindDirectionAddress({
                    type: "START",
                    coordinate: {
                         x: e.coord.x,
                         y: e.coord.y,
                    },
               });
          });

          endDiv.addEventListener("click", () => {
               infoWindow.close();

               setFindDirectionAddress({
                    type: "END",
                    coordinate: {
                         x: e.coord.x,
                         y: e.coord.y,
                    },
               });
          });

          const favoriteBtnDiv = document.createElement("div");
          favoriteBtnDiv.textContent = "즐겨찾기";
          favoriteBtnDiv.style.cssText = "cursor: pointer; width:100%; display:flex; justify-content:center; align-items:center; height:30px; padding: 5px;";

          favoriteBtnDiv.addEventListener("click", () => {
               setSelectedPosition(e.coord);
          });

          buttonContainer.appendChild(startDiv);
          buttonContainer.appendChild(endDiv);

          container.appendChild(totalContainer);
          totalContainer.appendChild(buttonContainer);
          totalContainer.appendChild(favoriteBtnDiv);

          infoWindow.setContent(container);
          infoWindow.open(map.current, e.coord);

          rightClickInfoWindowRef.current = infoWindow;
     });
};

export const handleMapLongTouch = ({
     map,
     selectedPositionRef,
     longPressDuration = LONGPRESS_DURATION,
     setSelectedPosition,
     directionWithPoint,
     handleClickSetDirectionWithPoint,
}) => {
     let pressTimer;
     let startCoord = null;

     const onTouchStart = e => {
          startCoord = e.coord;
          pressTimer = setTimeout(() => {
               if (startCoord) {
                    if (directionWithPoint.current) {
                         handleClickSetDirectionWithPoint(directionWithPoint.current, e.coord);
                         return;
                    } else {
                         const infoWindow = new naver.maps.InfoWindow({
                              anchorSkew: false,
                              maxWidth: 10,
                              borderColor: "transparent",
                              backgroundColor: "transparent",
                              anchorSize: { width: 0, height: 0 },
                         });

                         const container = document.createElement("div");
                         container.style.cssText = "margin-top:30px;padding-right:10px;";

                         const outerDiv = document.createElement("div");
                         outerDiv.id = "position-point";
                         outerDiv.style.cssText =
                              "border:0.1px solid #797979;width:30px;height:30px;border-radius:50%;background-color:white;display:flex;justify-content:center;align-items:center;";

                         const innerDiv = document.createElement("div");
                         innerDiv.style.cssText =
                              "width:20px;height:20px;border-radius:50%;background-color:#797979;display:flex;justify-content:center;align-items:center;";

                         outerDiv.appendChild(innerDiv);

                         container.appendChild(outerDiv);

                         infoWindow.setContent(container);
                         infoWindow.open(map.current, { x: e.coord.x - 0.00003, y: e.coord.y - 0.00008 });

                         selectedPositionRef.current = infoWindow;

                         setSelectedPosition(e.coord);
                    }
               }
          }, longPressDuration);
     };

     const onTouchMove = e => {
          if (startCoord) {
               const distance = Math.sqrt(Math.pow(e.coord.x - startCoord.x, 2) + Math.pow(e.coord.y - startCoord.y, 2));
               if (distance > 0.0001) {
                    clearTimeout(pressTimer);
                    pressTimer = null;
               }
          }
     };

     const onTouchEnd = () => {
          clearTimeout(pressTimer);
          pressTimer = null;
          startCoord = null;
     };

     return { onTouchStart, onTouchEnd, onTouchMove };
};

export const handleTobaccoMapLongTouch = ({
     map,
     directionChangeFlagRef,
     changePlaceRef,
     setStorePositionChangeModalOpen,
     changeInfoWindowRef,
     selectedPositionRef,
     longPressDuration = LONGPRESS_DURATION,
     setSelectedPosition,
     directionWithPoint,
     handleClickSetDirectionWithPoint,
}) => {
     let pressTimer;
     let startCoord = null;

     const onTouchStart = e => {
          startCoord = e.coord;
          pressTimer = setTimeout(() => {
               if (startCoord) {
                    if (directionChangeFlagRef.current) {
                         const infoWindow = new naver.maps.InfoWindow({
                              anchorSkew: false,
                              maxWidth: 100,
                              borderColor: "#666666",
                              borderWidth: 3,
                              anchorSize: { width: 8, height: 8 },
                         });

                         const container = document.createElement("div");
                         container.style.cssText =
                              "text-align:center;border-radius:16px;color: #3D4043;font-family: Pretendard;font-size: 12px;font-style: normal;font-weight: 700;padding:10px;min-width:100px;line-height:150%;background-color:white;";

                         changePlaceRef.current = e.coord;

                         const startDiv = document.createElement("div");
                         startDiv.id = "change-point";
                         startDiv.textContent = "클릭시 주소 지정!";
                         startDiv.style.cursor = "pointer";
                         startDiv.addEventListener("click", () => {
                              setStorePositionChangeModalOpen(true);
                         });

                         container.appendChild(startDiv);

                         infoWindow.setContent(container);

                         infoWindow.open(map.current, e.coord);
                         setTimeout(() => {
                              const parentElement = startDiv.parentElement?.parentElement?.parentElement;

                              if (parentElement) {
                                   parentElement.style.borderRadius = "20px";
                              }
                         }, 0);

                         changeInfoWindowRef.current = infoWindow;
                    } else if (directionWithPoint.current) {
                         handleClickSetDirectionWithPoint(directionWithPoint.current, e.coord);
                         return;
                    } else {
                         const infoWindow = new naver.maps.InfoWindow({
                              anchorSkew: false,
                              maxWidth: 10,
                              borderColor: "transparent",
                              backgroundColor: "transparent",
                              anchorSize: { width: 0, height: 0 },
                         });

                         const container = document.createElement("div");
                         container.style.cssText = "margin-top:30px;padding-right:10px;";
                         const outerDiv = document.createElement("div");
                         outerDiv.id = "position-point";
                         outerDiv.style.cssText =
                              "border:0.1px solid #797979;width:30px;height:30px;border-radius:50%;background-color:white;display:flex;justify-content:center;align-items:center;";

                         const innerDiv = document.createElement("div");
                         innerDiv.style.cssText =
                              "width:20px;height:20px;border-radius:50%;background-color:#797979;display:flex;justify-content:center;align-items:center;";

                         outerDiv.appendChild(innerDiv);

                         container.appendChild(outerDiv);

                         infoWindow.setContent(container);
                         infoWindow.open(map.current, { x: e.coord.x - 0.00003, y: e.coord.y - 0.00008 });

                         selectedPositionRef.current = infoWindow;

                         setSelectedPosition(e.coord);
                    }
               }
          }, longPressDuration);
     };

     const onTouchMove = e => {
          if (startCoord) {
               const distance = Math.sqrt(Math.pow(e.coord.x - startCoord.x, 2) + Math.pow(e.coord.y - startCoord.y, 2));
               if (distance > 0.0001) {
                    clearTimeout(pressTimer);
                    pressTimer = null;
               }
          }
     };

     const onTouchEnd = () => {
          clearTimeout(pressTimer);
          pressTimer = null;
          startCoord = null;
     };

     return { onTouchStart, onTouchEnd, onTouchMove };
};
