import styled from "@emotion/styled";

interface ISideBarTabItem {
     selected: boolean;
}

export const SearchWrap = styled.div`
     box-sizing: border-box;
     width: 100%;
     height: 42px;
     margin-bottom: 6px;
     border-radius: 8px;
     border: 1.5px solid #dadce0;
     background: #fff;
     display: flex;
     justify-content: space-between;
     align-items: center;
     overflow: hidden;
     padding: 10px;

     input {
          width: 100%;
          height: 100%;
          border: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.14px;

          &::placeholder {
               color: #71757a;
               opacity: 0.5;
          }
     }

     div {
          cursor: pointer;
     }
`;

export const SideBarContentsWrap = styled.div`
     box-sizing: border-box;
     width: 360px;

     height: 100%;
`;

export const SideBarTabWrap = styled.div`
     width: 100%;
     height: 50px;
     display: flex;
     justify-content: space-between;
     align-items: center;
`;

export const SideBarTabItem = styled.div<ISideBarTabItem>`
     cursor: pointer;
     border-radius: 8px;
     width: 165px;
     box-sizing: border-box;
     height: 44px;
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 6px;

     background-color: ${({ selected }) => (selected ? "rgba(32, 109, 239, 0.12)" : "transparent")};
     color: ${({ selected }) => (selected ? "#206DEF" : "rgba(80, 80, 80, 0.50)")};
     font-weight: ${({ selected }) => (selected ? "bold" : "300")};
     font-size: 15px;
     text-align: center;

     font-size: 16px;
     font-style: normal;
     font-weight: 500;
     line-height: 20px; /* 125% */
     letter-spacing: -0.23px;
     svg {
          padding-bottom: 1px;
     }
`;

export const SidebarTop = styled.div`
     width: 100%;
     height: 138px;
     border-bottom: 1px solid #dadce0;
     background: #f1f4f9;
     padding: 34px 20px 8px;
     box-sizing: border-box;
`;
