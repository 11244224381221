import styled from "styled-components";
import PcLeftWrap from "../../components/sidebar/PcLeftWrap";

import TabletOrMobileSidebar from "../../components/sidebar/TabletOrMobileSidebar";
import { OnIsTabletOrMobile } from "../../hook/widthSize";

import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Paper from "@mui/material/Paper";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reviewStatus, reviewInfo, ReviewCardType, sortBizplNmAsc, sortCreatedDesc, reviewInfoReset, reviewCardReset } from "../../reducers/review";
import { userInfo } from "../../reducers/user";

import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import CircularProgress from "@mui/material/CircularProgress";
import MobileBottomMenu from "../../components/mobileBottomMenu/MobileBottomMenu";

interface WindowSize {
     width: number | undefined;
     height: number | undefined;
}

const Report = () => {
     const { accounts } = useMsal();
     const dispatch = useDispatch();
     const isTabletOrMobile = OnIsTabletOrMobile();

     const reviewInfoData = useSelector((state: any) => state.review);
     const userInfoData = useSelector((state: any) => state.user);

     const [windowSize, setWindowSize] = useState<WindowSize>({
          width: undefined,
          height: undefined,
     });

     useEffect(() => {
          console.log("windowSize.height >> ", windowSize.height);

          if (typeof window !== "undefined") {
               const handleResize = () => {
                    setWindowSize({
                         width: window.innerWidth,
                         height: window.innerHeight,
                    });
               };

               window.addEventListener("resize", handleResize);
               handleResize();
               return () => window.removeEventListener("resize", handleResize);
          } else {
               return () =>
                    window.removeEventListener("resize", () => {
                         return null;
                    });
          }
     }, []);

     useEffect(() => {
          dispatch(reviewInfoReset());
          dispatch(reviewCardReset());
     }, [dispatch]);

     useEffect(() => {
          if (userInfoData?.kor_nm == null || userInfoData?.account === undefined) {
               dispatch(userInfo({ account: accounts[0]["username"], kor_nm: accounts[0]["name"] }));
          } else {
               dispatch(reviewInfo({ account: userInfoData.account }));
               dispatch(reviewStatus({ account: userInfoData.account }));
          }
     }, [accounts, dispatch, userInfoData.account]);

     const [alignment, setAlignment] = useState("created");
     const [cardFilter, setCardFilter] = useState("ALL");

     const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
          setAlignment(newAlignment);
     };

     useEffect(() => {
          if (alignment === "created") {
               dispatch(sortCreatedDesc());
          } else if (alignment === "name") {
               dispatch(sortBizplNmAsc());
          }
     }, [alignment, dispatch]);

     // 리뷰 카드 필터링
     const statusStyle = (status: String, isCurrent: String) => {
          if (isCurrent !== getBaseDate()) {
               return "#D9D9D9";
          } else if (status === "긍정") {
               return "#007AFF";
          } else if (status === "부정") {
               return "#F4462C";
          }
     };

     const [sentimental, setSentimental] = useState("");

     const setReviewDisplay = (selected: String, team_mng_yn: String, sm_fc_team_mng_yn: String, part: String, bizpl: String) => {
          var cardStatus = "none";
          var sentimentalStatus = "none";

          if (cardFilter == null || cardFilter === "" || cardFilter === "ALL") {
               cardStatus = "block";
          } else {
               if (team_mng_yn === "Y" || sm_fc_team_mng_yn === "Y") {
                    if (cardFilter === part) cardStatus = "block";
               } else {
                    if (cardFilter === bizpl) cardStatus = "block";
               }
          }

          if (sentimental == null || sentimental === "") {
               sentimentalStatus = "block";
          } else if (sentimental === "POS" && selected === "긍정") {
               sentimentalStatus = "block";
          } else if (sentimental === "NEU" && selected === "중립") {
               sentimentalStatus = "block";
          } else if (sentimental === "NEG" && selected === "부정") {
               sentimentalStatus = "block";
          }

          if (sentimentalStatus === "block" && cardStatus === "block") {
               return "block";
          } else {
               return "none";
          }
     };

     const handleSentimentalChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
          setSentimental(nextView);
     };

     const handleSelectChange = (event: SelectChangeEvent) => {
          setCardFilter(event.target.value);
     };

     const getBaseDate = () => {
          const today = new Date();
          today.setDate(today.getDate() - 1);

          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const day = String(today.getDate()).padStart(2, "0");

          return `${year}.${month}.${day}`;
     };

     return (
          <>
               <ReportWrap isTabletOrMobile={isTabletOrMobile}>
                    {!isTabletOrMobile ? <PcLeftWrap /> : <TabletOrMobileSidebar />}

                    {userInfoData === null || reviewInfoData === null ? (
                         <CircularProgress />
                    ) : (
                         <>
                              {/* PC 화면 */}
                              <Stack sx={{ minWidth: "820px" }} direction="row">
                                   <Box sx={{ minWidth: "350px", width: "350px", display: { xs: "none", sm: "block" } }}>
                                        <Grid container>
                                             <Grid xs={12}>
                                                  <Typography variant="h6" sx={{ paddingY: 2, paddingX: 2, fontWeight: 600 }}>
                                                       {userInfoData?.team_nm}
                                                  </Typography>
                                                  <Stack direction="row" spacing={1} sx={{ paddingX: 2, paddingBottom: 3 }}>
                                                       <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
                                                            {userInfoData?.kor_nm}
                                                       </Typography>
                                                       {reviewInfoData?.review_count && (
                                                            <Chip sx={{ backgroundColor: "#1976D2", color: "#FFF", fontWeight: 600 }}>
                                                                 {reviewInfoData?.review_count}
                                                            </Chip>
                                                       )}
                                                  </Stack>
                                             </Grid>
                                             <ToggleButtonGroup
                                                  value={sentimental}
                                                  exclusive
                                                  onChange={handleSentimentalChange}
                                                  sx={{ paddingX: 1, paddingBottom: 3 }}
                                             >
                                                  <ToggleButton
                                                       value="POS"
                                                       sx={{ py: "5px", "&.Mui-selected": { backgroundColor: "rgba(0,122,255,0.2)", color: "#fff" } }}
                                                  >
                                                       <Stack
                                                            direction="column"
                                                            spacing={1}
                                                            alignItems={"center"}
                                                            sx={{ fontWeight: 300, color: "#007AFF", width: "calc(((350px - 20.5px)/3) - 22px)" }}
                                                       >
                                                            <div className="clova-card">
                                                                 {sentimental === "POS" ? <FilterListIcon sx={{ fontSize: "20px" }} /> : null}긍정
                                                            </div>
                                                            <div className="clova-count">{reviewInfoData?.positive}</div>
                                                       </Stack>
                                                  </ToggleButton>
                                                  <ToggleButton
                                                       value="NEU"
                                                       sx={{ py: "5px", "&.Mui-selected": { backgroundColor: "rgba(96,96,96,0.2)", color: "#fff" } }}
                                                  >
                                                       <Stack
                                                            direction="column"
                                                            spacing={1}
                                                            alignItems={"center"}
                                                            sx={{ fontWeight: 300, color: "#605F60", width: "calc(((350px - 20.5px)/3) - 22px)" }}
                                                       >
                                                            <div className="clova-card">
                                                                 {sentimental === "NEU" ? <FilterListIcon sx={{ fontSize: "20px" }} /> : null}중립
                                                            </div>
                                                            <div className="clova-count">{reviewInfoData?.neutral}</div>
                                                       </Stack>
                                                  </ToggleButton>
                                                  <ToggleButton
                                                       value="NEG"
                                                       sx={{ py: "5px", "&.Mui-selected": { backgroundColor: "rgba(244,70,44,0.2)", color: "#fff" } }}
                                                  >
                                                       <Stack
                                                            direction="column"
                                                            spacing={1}
                                                            alignItems={"center"}
                                                            sx={{ fontWeight: 300, color: "#F4462C", width: "calc(((350px - 20.5px)/3) - 22px)" }}
                                                       >
                                                            <div className="clova-card">
                                                                 {sentimental === "NEG" ? <FilterListIcon sx={{ fontSize: "20px" }} /> : null}부정
                                                            </div>
                                                            <div className="clova-count">{reviewInfoData?.negative}</div>
                                                       </Stack>
                                                  </ToggleButton>
                                             </ToggleButtonGroup>
                                        </Grid>
                                        <RadioGroup
                                             value={cardFilter}
                                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCardFilter(event.target.value)}
                                             sx={{ minHeight: 48, padding: "4px", marginRight: 2 }}
                                        >
                                             <Stack direction="row" spacing={1} justifyContent={"space-between"} width={"100%"} alignItems={"start"}>
                                                  <Radio
                                                       key={"ALL"}
                                                       value={"ALL"}
                                                       label={"전체 선택"}
                                                       sx={{
                                                            px: 2,
                                                            height: 30,
                                                            fontWeight: 700,
                                                            fontSize: "17px",
                                                       }}
                                                  />
                                             </Stack>
                                             {reviewInfoData.reviewStatus?.map((item: any) => (
                                                  <Stack
                                                       key={item.filter_id}
                                                       direction="row"
                                                       spacing={1}
                                                       justifyContent={"space-between"}
                                                       width={"100%"}
                                                       alignItems={"start"}
                                                  >
                                                       <Radio
                                                            key={item.filter_id}
                                                            value={item.filter_id}
                                                            label={item.filter_nm}
                                                            sx={{
                                                                 px: 2,
                                                                 height: 30,
                                                                 fontWeight: 500,
                                                                 fontSize: "16px",
                                                                 // fontWeight: 700,
                                                                 color: item?.match_yn === "Y" ? "#000" : "rgba(96,96,96,0.5)",
                                                            }}
                                                       />
                                                       {item?.match_yn === "Y" ? (
                                                            <Stack direction="row">
                                                                 <Chip
                                                                      size="md"
                                                                      sx={{
                                                                           backgroundColor: "#1976D2",
                                                                           color: "#FFF",
                                                                           border: "1px solid #DFDFDF",
                                                                           fontSize: "14px",
                                                                      }}
                                                                 >
                                                                      {(sentimental === "POS"
                                                                           ? item?.positive
                                                                           : sentimental === "NEG"
                                                                             ? item?.negative
                                                                             : sentimental === "NEU"
                                                                               ? item?.neutral
                                                                               : item?.positive + item?.neutral + item?.negative) === 0
                                                                           ? "-"
                                                                           : sentimental === "POS"
                                                                             ? item?.positive
                                                                             : sentimental === "NEG"
                                                                               ? item?.negative
                                                                               : sentimental === "NEU"
                                                                                 ? item?.neutral
                                                                                 : item?.positive + item?.neutral + item?.negative}
                                                                 </Chip>
                                                            </Stack>
                                                       ) : (
                                                            <Stack direction="row">
                                                                 <Chip
                                                                      size="md"
                                                                      sx={{
                                                                           backgroundColor: "rgba(96,96,96,0.2)",
                                                                           color: "#FFF",
                                                                           border: "1px solid #DFDFDF",
                                                                           fontSize: "16px",
                                                                      }}
                                                                 >
                                                                      -
                                                                 </Chip>
                                                            </Stack>
                                                       )}
                                                  </Stack>
                                             ))}
                                        </RadioGroup>
                                   </Box>
                                   <Box
                                        sx={{
                                             paddingLeft: 3,
                                             minHeight: windowSize.height + "px",
                                             minWidth: "870px",
                                             width: "100vw",
                                             flexGrow: 1,
                                             direction: "row",
                                             justifyContent: "flex-start",
                                             alignItems: "flex-start",
                                             display: { xs: "none", sm: "block" },
                                             backgroundColor: "#F5F6FA",
                                        }}
                                   >
                                        {/* <Box sx={{paddingLeft: 3, minHeight: '100vh', minWidth: '1025px', width: '100vw-350px', flexGrow: 1,  direction: "row", justifyContent: "flex-start", alignItems: "flex-start", display:{xs: 'none', sm: 'block'}, backgroundColor: '#F5F6FA'}}> */}
                                        <Grid container spacing={0} justifyContent={"space-between"}>
                                             <Grid xs={3}>
                                                  <Typography variant="h6" sx={{ paddingY: 2, fontWeight: 600 }}>
                                                       {userInfoData?.sm_mng_yn === "N" ? "리뷰 현황 (최근 3개월)" : "리뷰 현황 (최근 1개월)"}
                                                  </Typography>
                                             </Grid>
                                             <Grid xs={3} direction={"row"} textAlign={"right"} paddingRight={"15px"}>
                                                  <ToggleButtonGroup
                                                       color="info"
                                                       size="small"
                                                       sx={{ paddingY: 2, textAlign: "right" }}
                                                       value={alignment}
                                                       exclusive
                                                       onChange={handleChange}
                                                       aria-label="Platform"
                                                  >
                                                       <ToggleButton sx={{ "&.Mui-selected": { backgroundColor: "#1976d2", color: "#fff" } }} value="created">
                                                            등록일 순
                                                       </ToggleButton>
                                                       <ToggleButton sx={{ "&.Mui-selected": { backgroundColor: "#1976d2", color: "#fff" } }} value="name">
                                                            점포명 순
                                                       </ToggleButton>
                                                  </ToggleButtonGroup>
                                             </Grid>
                                        </Grid>
                                        <Paper
                                             elevation={0}
                                             sx={{
                                                  height: "calc(" + windowSize.height + "px - 65px)",
                                                  overflow: "scroll",
                                                  backgroundColor: "#F5F6FA",
                                             }}
                                             className="review-list"
                                        >
                                             {/* <Paper elevation={0} sx={{height: 'calc(100vh - 65px)', overflow: 'scroll', backgroundColor: '#F5F6FA'}} className="review-list"> */}
                                             <Grid container spacing={0}>
                                                  {reviewInfoData?.reviewList?.length > 0 ? (
                                                       reviewInfoData?.reviewList?.map((review: ReviewCardType, index: number) => (
                                                            <Grid
                                                                 key={index}
                                                                 sx={{
                                                                      py: 1,
                                                                      paddingRight: 1,
                                                                      display: setReviewDisplay(
                                                                           review.clova_sentiment,
                                                                           userInfoData?.team_mng_yn,
                                                                           userInfoData?.sm_fc_team_mng_yn,
                                                                           review.part_mng_id,
                                                                           review.bizpl_cd,
                                                                      ),
                                                                 }}
                                                            >
                                                                 <Box
                                                                      component="section"
                                                                      className={
                                                                           review.author_created === getBaseDate() ? "pc-review-card-new" : "pc-review-card-old"
                                                                      }
                                                                      sx={{
                                                                           p: 2,
                                                                           border: "1px solid",
                                                                           borderColor: statusStyle(review.clova_sentiment, review.author_created),
                                                                      }}
                                                                 >
                                                                      <Grid container rowSpacing={2}>
                                                                           <Grid xs={10} sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
                                                                                {review.bizpl_nm}
                                                                           </Grid>
                                                                           <Grid xs={2} sx={{ textAlign: "right" }}>
                                                                                <Chip
                                                                                     className={
                                                                                          review.clova_sentiment === "긍정"
                                                                                               ? "label-positive"
                                                                                               : review.clova_sentiment === "부정"
                                                                                                 ? "label-negative"
                                                                                                 : "label-neutral"
                                                                                     }
                                                                                     sx={{ fontWeight: 900, height: "35px" }}
                                                                                >
                                                                                     {review.clova_sentiment}
                                                                                </Chip>
                                                                           </Grid>
                                                                           <Grid xs={6} className="author-info">
                                                                                닉네임: {review.author_nickname}
                                                                           </Grid>
                                                                           <Grid xs={6} sx={{ textAlign: "right" }} className="author-info">
                                                                                작성일자: {review.author_created}
                                                                           </Grid>
                                                                           <Grid
                                                                                xs={12}
                                                                                sx={{
                                                                                     height: "100px",
                                                                                     overflow: "scroll",
                                                                                     border: "1px dashed #D9D9D9",
                                                                                     borderRadius: "5px",
                                                                                     p: 1,
                                                                                }}
                                                                                className="review-card"
                                                                           >
                                                                                {review.body}
                                                                           </Grid>
                                                                           <Grid xs={6} className="author-info">
                                                                                점포 리뷰 횟수: {review.visit_count}
                                                                           </Grid>
                                                                           <Grid xs={6} sx={{ textAlign: "right" }} className="author-info">
                                                                                방문일자: {review.author_visited}
                                                                           </Grid>
                                                                      </Grid>
                                                                 </Box>
                                                            </Grid>
                                                       ))
                                                  ) : (
                                                       <Grid>
                                                            <Box
                                                                 component="section"
                                                                 sx={{
                                                                      width: { xs: "740px", sm: "390px", md: "390px" },
                                                                      height: "200px",
                                                                      p: 2,
                                                                      border: "1px solid",
                                                                      borderColor: "#D9D9D9",
                                                                      borderRadius: "10px",
                                                                      backgroundColor: "#FFF",
                                                                 }}
                                                            >
                                                                 <CircularProgress />
                                                            </Box>
                                                       </Grid>
                                                  )}
                                             </Grid>
                                        </Paper>
                                   </Box>
                              </Stack>

                              {/* 모바일 화면 */}
                              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                                   <Grid container>
                                        <Grid xs={12}>
                                             <Stack direction="row">
                                                  <Typography variant="h6" sx={{ paddingTop: 2, paddingX: 2, fontWeight: 900 }}>
                                                       {userInfoData?.team_nm}
                                                  </Typography>
                                                  <Stack direction="row" spacing={1} sx={{ paddingX: 2, paddingTop: 2, paddingBottom: 2 }}>
                                                       <Typography variant="h6" sx={{ fontWeight: 900 }}>
                                                            {userInfoData?.kor_nm}
                                                       </Typography>
                                                       <Chip sx={{ backgroundColor: "#1976D2", color: "#FFF", fontWeight: 600 }}>
                                                            {reviewInfoData?.review_count}
                                                       </Chip>
                                                  </Stack>
                                             </Stack>
                                        </Grid>
                                        <ToggleButtonGroup
                                             value={sentimental}
                                             exclusive
                                             onChange={handleSentimentalChange}
                                             sx={{ paddingX: 1, paddingBottom: 1 }}
                                        >
                                             <ToggleButton
                                                  value="POS"
                                                  sx={{ py: "2px", "&.Mui-selected": { backgroundColor: "rgba(0,122,255,0.2)", color: "#fff" } }}
                                             >
                                                  <Stack
                                                       direction="column"
                                                       alignItems={"center"}
                                                       sx={{ fontWeight: 300, color: "#007AFF", width: "calc(((100vw - 20.5px)/3) - 22px)" }}
                                                  >
                                                       <div className="clova-card">
                                                            {sentimental === "POS" ? <FilterListIcon sx={{ fontSize: "20px" }} /> : null}긍정
                                                       </div>
                                                       <div className="clova-count">{reviewInfoData?.positive}</div>
                                                  </Stack>
                                             </ToggleButton>
                                             <ToggleButton
                                                  value="NEU"
                                                  sx={{ py: "2px", "&.Mui-selected": { backgroundColor: "rgba(96,96,96,0.2)", color: "#fff" } }}
                                             >
                                                  <Stack
                                                       direction="column"
                                                       alignItems={"center"}
                                                       sx={{ fontWeight: 300, color: "#605F60", width: "calc(((100vw - 20.5px)/3) - 22px)" }}
                                                  >
                                                       <div className="clova-card">
                                                            {sentimental === "NEU" ? <FilterListIcon sx={{ fontSize: "20px" }} /> : null}중립
                                                       </div>
                                                       <div className="clova-count">{reviewInfoData?.neutral}</div>
                                                  </Stack>
                                             </ToggleButton>
                                             <ToggleButton
                                                  value="NEG"
                                                  sx={{ py: "2px", "&.Mui-selected": { backgroundColor: "rgba(244,70,44,0.2)", color: "#fff" } }}
                                             >
                                                  <Stack
                                                       direction="column"
                                                       alignItems={"center"}
                                                       sx={{ fontWeight: 300, color: "#F4462C", width: "calc(((100vw - 20.5px)/3) - 22px)" }}
                                                  >
                                                       <div className="clova-card">
                                                            {sentimental === "NEG" ? <FilterListIcon sx={{ fontSize: "20px" }} /> : null}부정
                                                       </div>
                                                       <div className="clova-count">{reviewInfoData?.negative}</div>
                                                  </Stack>
                                             </ToggleButton>
                                        </ToggleButtonGroup>
                                   </Grid>

                                   <Stack direction="row">
                                        <FilterListIcon sx={{ marginLeft: 1, width: "30px", height: "30px", paddingTop: "5px" }} />
                                        <Select
                                             labelId="demo-select-small-label"
                                             id="demo-select-small"
                                             onChange={handleSelectChange}
                                             value={cardFilter}
                                             sx={{ marginX: 1, marginBottom: 1, width: "calc(100vw - 16px)" }}
                                        >
                                             <MenuItem value={"ALL"}>전체 선택</MenuItem>
                                             {reviewInfoData.reviewStatus?.map((item: any) => (
                                                  <MenuItem
                                                       key={item?.filter_id}
                                                       value={item?.filter_id}
                                                       sx={{ color: item?.match_yn === "Y" ? "#000" : "rgba(96,96,96,0.5)" }}
                                                  >
                                                       {item?.filter_nm}
                                                       {(sentimental === "POS"
                                                            ? item?.positive
                                                            : sentimental === "NEG"
                                                              ? item?.negative
                                                              : sentimental === "NEU"
                                                                ? item?.neutral
                                                                : item?.positive + item?.neutral + item?.negative) === 0
                                                            ? ""
                                                            : " (" +
                                                              (sentimental === "POS"
                                                                   ? item?.positive
                                                                   : sentimental === "NEG"
                                                                     ? item?.negative
                                                                     : sentimental === "NEU"
                                                                       ? item?.neutral
                                                                       : item?.positive + item?.neutral + item?.negative) +
                                                              ")"}
                                                  </MenuItem>
                                             ))}
                                        </Select>
                                   </Stack>
                              </Box>
                              <Box
                                   sx={{
                                        minHeight: windowSize.height + "px",
                                        flexGrow: 1,
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        display: { xs: "block", sm: "none" },
                                   }}
                              >
                                   {/* <Box sx={{ minHeight: '100vh', flexGrow: 1,  direction: "row", justifyContent: "flex-start", alignItems: "flex-start", display:{xs: 'block', sm: 'none'}}}> */}
                                   <Grid xs={12}>
                                        <Typography variant="h6" sx={{ paddingY: 1, paddingX: 2, fontWeight: 900, fontSize: "1.0rem" }}>
                                             {userInfoData?.sm_mng_yn === "N" ? "리뷰 현황 (최근 3개월)" : "리뷰 현황 (최근 1개월)"}
                                        </Typography>
                                   </Grid>
                                   <Paper
                                        elevation={0}
                                        sx={{ height: "calc(" + windowSize.height + "px - 365px)", overflow: "scroll" }}
                                        className="review-list"
                                   >
                                        {/* <Paper elevation={0} sx={{height: 'calc(100vh - 285px)', overflow: 'scroll'}} className="review-list"> */}
                                        <Grid container spacing={2} sx={{ marginLeft: "3px", marginRight: "3px" }}>
                                             {reviewInfoData?.reviewList?.length > 0 ? (
                                                  reviewInfoData?.reviewList?.map((review: ReviewCardType, index: number) => (
                                                       <Grid
                                                            key={index}
                                                            sx={{
                                                                 display: setReviewDisplay(
                                                                      review.clova_sentiment,
                                                                      userInfoData?.team_mng_yn,
                                                                      userInfoData?.sm_fc_team_mng_yn,
                                                                      review.part_mng_id,
                                                                      review.bizpl_cd,
                                                                 ),
                                                            }}
                                                       >
                                                            <Box
                                                                 component="section"
                                                                 className={
                                                                      review.author_created === getBaseDate()
                                                                           ? "mobile-review-card-new"
                                                                           : "mobile-review-card-old"
                                                                 }
                                                                 sx={{
                                                                      p: 2,
                                                                      border: "1px solid",
                                                                      borderColor: statusStyle(review.clova_sentiment, review.author_created),
                                                                 }}
                                                            >
                                                                 <Grid container rowSpacing={2}>
                                                                      <Grid xs={10} sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
                                                                           {review.bizpl_nm}
                                                                      </Grid>
                                                                      <Grid xs={2} sx={{ textAlign: "right" }}>
                                                                           <Chip
                                                                                className={
                                                                                     review.clova_sentiment === "긍정"
                                                                                          ? "label-positive"
                                                                                          : review.clova_sentiment === "부정"
                                                                                            ? "label-negative"
                                                                                            : "label-neutral"
                                                                                }
                                                                                sx={{ fontWeight: 900 }}
                                                                           >
                                                                                {review.clova_sentiment}
                                                                           </Chip>
                                                                      </Grid>
                                                                      <Grid xs={6} className="author-info">
                                                                           닉네임: {review.author_nickname}
                                                                      </Grid>
                                                                      <Grid xs={6} sx={{ textAlign: "right" }} className="author-info">
                                                                           작성일자: {review.author_created}
                                                                      </Grid>
                                                                      <Grid
                                                                           xs={12}
                                                                           sx={{
                                                                                height: "100px",
                                                                                overflow: "scroll",
                                                                                border: "1px dashed #D9D9D9",
                                                                                borderRadius: "5px",
                                                                                p: 1,
                                                                           }}
                                                                           className="review-card"
                                                                      >
                                                                           {review.body}
                                                                      </Grid>
                                                                      <Grid xs={6} className="author-info">
                                                                           점포 리뷰 횟수: {review.visit_count}
                                                                      </Grid>
                                                                      <Grid xs={6} sx={{ textAlign: "right" }} className="author-info">
                                                                           방문일자: {review.author_visited}
                                                                      </Grid>
                                                                 </Grid>
                                                            </Box>
                                                       </Grid>
                                                  ))
                                             ) : (
                                                  <Grid>
                                                       <Box
                                                            component="section"
                                                            sx={{
                                                                 width: { xs: "740px", sm: "390px", md: "390px" },
                                                                 height: "200px",
                                                                 p: 2,
                                                                 border: "1px solid",
                                                                 borderColor: "#D9D9D9",
                                                                 borderRadius: "10px",
                                                                 backgroundColor: "#FFF",
                                                            }}
                                                       >
                                                            <CircularProgress />
                                                       </Box>
                                                  </Grid>
                                             )}
                                        </Grid>
                                   </Paper>
                              </Box>
                         </>
                    )}
               </ReportWrap>
               {isTabletOrMobile && <MobileBottomMenu />}
          </>
     );
};

export default Report;

interface ReportStyledProps {
     isTabletOrMobile: boolean;
}

const ReportWrap = styled.div<ReportStyledProps>`
     transform: translate3d(0, 0, 0);
     -webkit-transform: translateZ(0);
     -webkit-overflow-scrolling: touch;

     width: 100vw;
     height: 100vh;
     font-family: "Noto Sans KR", sans-serif;

     .MuiButtonBase-root-MuiCheckbox-root {
          padding: 0;
     }

     ${({ isTabletOrMobile }) => (isTabletOrMobile ? "display:block;" : "display:flex;")}
     .iframe-body {
          ${({ isTabletOrMobile }) => (isTabletOrMobile ? "padding: 0;" : "padding:20px 20px 0;")}
          flex: 1;
          height: calc(100vh - 64px);
     }

     iframe {
          height: 100%;
     }

     .MuiOutlinedInput-input {
          padding: 8px 14px;
     }

     .review-list {
          flex: 1;
          overflow-y: auto;
          overflow-x: auto;
          overscroll-behavior: none;
          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }
     }

     .MuiFormControlLabel-label {
          font-size: 1rem;
          font-family: "Noto Sans KR", sans-serif;
     }

     .MuiTypography-root {
          font-family: "Noto Sans KR", sans-serif;
     }

     .MuiRadio-input {
          font-family: "Noto Sans KR", sans-serif;
     }

     .MuiRadio-label {
          font-family: "Noto Sans KR", sans-serif;
     }

     .MuiChip-label {
          min-width: 25px;
          text-align: center;
          font-family: "Noto Sans KR", sans-serif;
     }

     .clova-card {
          font-size: 15px;
          font-weight: 500;
          font-family: "Noto Sans KR", sans-serif;
     }

     .clova-count {
          margin-top: 0px;
          font-size: 19px;
          font-weight: 600;
          font-family: "Noto Sans KR", sans-serif;
     }

     .author-info {
          font-size: 0.8rem;
          color: #605f60;
     }

     .review-card {
          // flex: 1;
          // OVERFLOW-Y: auto;
          color: #000;
          font-weight: 500;
          overscroll-behavior: none;
          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }
     }

     .label-positive {
          background-color: rgb(0, 122, 255, 0.2);
          color: #007aff;
     }

     .label-negative {
          background-color: rgb(244, 70, 44, 0.2);
          color: #f4462c;
     }

     .label-neutral {
          background-color: rgb(96, 96, 96, 0.2);
          color: #605f60;
     }

     .mobile-review-card-new {
          height: 200px;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
          border-radius: 7px;
     }
     .mobile-review-card-old {
          height: 200px;
          border-radius: 7px;
     }

     .pc-review-card-new {
          width: 390px;
          height: 200px;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
          border-radius: 7px;
          background-color: #fff;
     }
     .pc-review-card-old {
          width: 390px;
          height: 200px;
          border-radius: 7px;
          background-color: #fff;
     }
`;
